import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChalkboardTeacher, FaUserGraduate, FaUserTie, FaBookOpen, FaCalendarAlt } from 'react-icons/fa';
import FooterNav from '../components/FooterNav';
import img from '../../imgs/AdminHomeBackground.png';
import { AccountContext } from '../../UserAuth/Account';

const AdminHome = () => {
    const { getSession } = useContext(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { userGroups } = await getSession();

                // Check if the user belongs to the "Admin" user group
                if (!(userGroups && userGroups.includes('Admin'))) {
                    navigate('/forbidden');  // Redirect to Forbidden page
                }
            } catch (err) {
                console.error("Session error:", err);
                navigate('/'); // Redirect to login page if error in session or user not found
            }
        };

        fetchSession();
    }, [getSession, navigate]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const buttonData = [
        { label: 'Instructors', icon: FaChalkboardTeacher, path: '/admin/instructors' },
        { label: 'Students', icon: FaUserGraduate, path: '/admin/students' },
        { label: 'Parents', icon: FaUserTie, path: '/admin/parents' },
        { label: 'Courses', icon: FaBookOpen, path: '/admin/courses' },
        { label: 'Events', icon: FaCalendarAlt, path: '/admin/events' } // Added Events to buttonData
    ];

    const containerStyle = {
        fontFamily: 'Arial, sans-serif',
        color: '#fff',
        backgroundImage: `url(${img})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '20px',
        minHeight: '100vh',
        paddingBottom: '80px',
    };

    const welcomeHeaderStyle = {
        fontSize: '2.0em',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '10px',
        marginBottom: '30px',
        color: '#f7f7f7',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        borderRadius: '8px',
    };

    const buttonStyle = {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '3em',
        alignItems: 'center',
        padding: '6px',
        border: 'none',
        borderRadius: '10px',
        width: '300px',
        cursor: 'pointer',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        textAlign: 'center',
        marginBottom: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        color: 'black',
    };

    const buttonHoverStyle = {
        backgroundColor: '#e0e0e0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    };

    return (
        <div style={containerStyle}>
            <div style={welcomeHeaderStyle}>Welcome to the Admin Page</div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {buttonData.map((button) => (
                    <div
                        key={button.label}
                        style={buttonStyle}
                        onMouseOver={(e) => Object.assign(e.currentTarget.style, buttonHoverStyle)}
                        onMouseOut={(e) => Object.assign(e.currentTarget.style, buttonStyle)}
                        onClick={() => handleNavigation(button.path)}
                    >
                        <button.icon size={50} color="#333" />
                        <span>{button.label}</span>
                    </div>
                ))}
            </div>

            {/* Footer Navigation */}
            <FooterNav />
        </div>
    );
};

export default AdminHome;
