import React, { useEffect, useState } from 'react';
import MessagePreview from './MessagePreview';
import axios from 'axios';
import { messageListStyles, messageItemStyles, popupStyles } from '../styles/Components/MessageListStyles';

const MessageList = ({ userId, messageType }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState({});
    const [error, setError] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [newMessage, setNewMessage] = useState({ to: '', subject: '', content: '' });

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                let response;
                if (messageType === 'trash') {
                    response = await axios.get(`${apiUrl}/api/messages/${userId}/trash`);
                } else if (messageType === 'sent') {
                    response = await axios.get(`${apiUrl}/api/messages/${userId}/sent`);
                } else {
                    response = await axios.get(`${apiUrl}/api/messages/${userId}/inbox`);
                }
                setMessages(response.data);
            } catch (err) {
                console.error('Error fetching messages:', err);
                setError('Failed to load messages. Please try again later.');
            }
        };
        if (messageType !== 'sendMessage') fetchMessages();
    }, [userId, messageType]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/users`);
                const usersData = response.data;
                const usersMap = {};
                usersData.forEach(user => {
                    usersMap[user.user_id] = `${user.first_name} ${user.last_name} (${user.user_type})`;
                });
                setUsers(usersMap);
            } catch (err) {
                console.error('Error fetching users:', err);
                setError('Failed to load users. Please try again later.');
            }
        };
        fetchUsers();
    }, []);

    const handleClick = (message) => setSelectedMessage(message);
    const handleClose = () => setSelectedMessage(null);

    const handleSendMessage = async () => {
        try {
            const recipientId = Object.keys(users).find(
                id => users[id].startsWith(newMessage.to)
            );

            if (!recipientId) {
                setError('Recipient not found. Please enter a valid name.');
                return;
            }

            const response = await axios.post('${apiUrl}/api/messages', {
                sender_id: userId,
                recipient_id: recipientId,
                subject: newMessage.subject,
                message: newMessage.content,
            });

            console.log('Message sent successfully:', response.data);
            setNewMessage({ to: '', subject: '', content: '' });
            setError(null);
        } catch (err) {
            console.error('Error sending message:', err);
            setError('Failed to send the message. Please try again.');
        }
    };

    const handleDeleteMessage = async (messageId, senderId) => {
        try {
            // Check if the current user is the sender of the message
            const isSender = parseInt(userId) === parseInt(senderId);

            // Detailed logging for troubleshooting
            console.log("Checking IDs for deletion:");
            console.log("Current User ID (userId):", userId, "Type:", typeof userId);
            console.log("Sender ID (senderId):", senderId, "Type:", typeof senderId);
            console.log("Is Sender?", isSender);

            await axios.put(`${apiUrl}/api/messages/${messageId}/delete`, {
                userID: userId,
                isSender,
            });

            // Remove the deleted message from the displayed list
            setMessages(prevMessages => prevMessages.filter(msg => msg.message_id !== messageId));
        } catch (err) {
            console.error('Error deleting message:', err);
            setError('Failed to delete the message. Please try again.');
        }
    };

    return (
        <div style={messageListStyles.container}>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {messageType === 'sendMessage' ? (
                <div style={{ padding: '20px' }}>
                    <h3>Send New Message</h3>
                    <input
                        type="text"
                        placeholder="To (Recipient Name)"
                        value={newMessage.to}
                        onChange={(e) => setNewMessage({ ...newMessage, to: e.target.value })}
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '10px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                        }}
                    />
                    <input
                        type="text"
                        placeholder="Subject"
                        value={newMessage.subject}
                        onChange={(e) => setNewMessage({ ...newMessage, subject: e.target.value })}
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '10px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                        }}
                    />
                    <textarea
                        placeholder="Message content"
                        value={newMessage.content}
                        onChange={(e) => setNewMessage({ ...newMessage, content: e.target.value })}
                        style={{
                            width: '100%',
                            padding: '10px',
                            height: '200px',
                            marginBottom: '10px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                        }}
                    />
                    <button
                        onClick={handleSendMessage}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                        }}
                    >
                        Send
                    </button>
                </div>
            ) : (
                messages.map((message, index) => (
                    <div key={index} style={messageItemStyles}>
                        <MessagePreview
                            message={{
                                ...message,
                                sender_name: users[message.sender_id] || 'Unknown Sender',
                                recipient_name: users[message.recipient_id] || 'Unknown Recipient'
                            }}
                            onClick={() => handleClick(message)}
                            // Conditionally render the delete button for trash messages
                            deleteMessage={messageType !== 'trash' ? () => handleDeleteMessage(message.message_id, message.sender_id) : null}
                        />
                    </div>
                ))
            )}

            {selectedMessage && (
                <div style={{ ...popupStyles.container }}>
                    <div style={popupStyles.content}>
                        <button style={popupStyles.closeButton} onClick={handleClose}>X</button>
                        <h3>{selectedMessage.subject}</h3>
                        <p>From: {users[selectedMessage.sender_id] || 'Unknown Sender'}</p>
                        <p>To: {users[selectedMessage.recipient_id] || 'Unknown Recipient'}</p>
                        <p>{selectedMessage.message}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessageList;
