export const messageListStyles = {
    container: {
        position: 'fixed',
        top: '0px',
        right: '40px',
        width: 'calc(65%)', // Takes 65% of the screen width
        height: 'calc(100vh - 90px)', // Adjust for both header (if any) and toolbar
        overflowY: 'auto', // Allow scrolling when content exceeds height
        overflowX: 'hidden', // Hide horizontal overflow
        backgroundColor: 'rgba(211, 211, 211, 0.9)', // Slightly translucent background
        padding: '20px',
        textAlign: 'left',
        zIndex: 10,

        // Prevent clipping by ensuring space is available and content scrolls
        boxSizing: 'border-box', // Include padding in the element's total width and height
    },
};

export const messageItemStyles = {
    cursor: 'pointer',
    padding: '10px',
    borderBottom: '1px solid #ccc',
    whiteSpace: 'nowrap', // Prevent text wrapping
    overflow: 'hidden', // Prevent overflow of text
    textOverflow: 'ellipsis', // Show ellipsis if text overflows
};

// Popup styles for sliding effect
export const popupStyles = {
    container: {
        position: 'fixed',
        top: '40px', // Space from the top
        right: '0',
        width: '40%', // Takes 40% of the screen width
        height: '100vh',
        backgroundColor: 'white',
        boxShadow: '-2px 0 10px rgba(0, 0, 0, 0.5)',
        zIndex: 20,
        transition: 'transform 0.3s ease-in-out', // Smooth slide transition

        // Prevent clipping with proper overflow handling
        overflowY: 'auto', // Allow vertical scrolling if content exceeds height
        overflowX: 'hidden', // Hide horizontal overflow
    },
    content: {
        padding: '20px',
        height: '100%',
        overflowY: 'auto', // Allow vertical scrolling inside the popup content
        boxSizing: 'border-box', // Include padding in the total height calculations
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'red',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    },
};
