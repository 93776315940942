import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './StudentDetails.css';

const StudentDetails = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { studentId } = useParams();  // Get the student ID from the URL
    const [student, setStudent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);  // Track whether editing is enabled
    const [editData, setEditData] = useState({});  // Store editable data
    const navigate = useNavigate();

    // Fetch the student's details including parents and courses
    useEffect(() => {
        const fetchStudentDetails = async () => {
            try {
                const studentResponse = await axios.get(`${apiUrl}/api/students/${studentId}`);
                setStudent(studentResponse.data);
                setEditData(studentResponse.data); // Set initial edit data
                setLoading(false);
            } catch (error) {
                console.error('Error fetching student details:', error);
                setError('Failed to load student details.');
                setLoading(false);
            }
        };

        fetchStudentDetails();
    }, [studentId]);

    // Handle field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    // Handle update action
    const handleUpdate = async () => {
        try {
            await axios.put(`${apiUrl}/api/students/${studentId}`, editData);
            setStudent(editData);  // Update the student with edited data
            setIsEditing(false);  // Exit editing mode
            alert('Student updated successfully.');
        } catch (error) {
            console.error('Error updating student:', error);
            alert('Failed to update student.');
        }
    };

    // Handle delete action
    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this student?')) {
            try {
                await axios.delete(`${apiUrl}/api/students/${studentId}`);
                alert('Student deleted successfully.');
                navigate('/admin/students');  // Redirect to the students list after deletion
            } catch (error) {
                console.error('Error deleting student:', error);
                alert('Failed to delete student.');
            }
        }
    };

    if (loading) return <p>Loading student details...</p>;
    if (error) return <p>{error}</p>;
    if (!student) return <p>No student details available.</p>;

    return (
        <div className="student-details-container">
            <h2>{student.first_name} {student.last_name}'s Details</h2>

            <div>
                <h3>Basic Information</h3>
                {isEditing ? (
                    <div>
                        <p>
                            <strong>ID:</strong> {student.primary_id}
                        </p>
                        <p>
                            <label><strong>First Name:</strong></label>
                            <input type="text" name="first_name" value={editData.first_name} onChange={handleChange} />
                        </p>
                        <p>
                            <label><strong>Last Name:</strong></label>
                            <input type="text" name="last_name" value={editData.last_name} onChange={handleChange} />
                        </p>
                        <p>
                            <label><strong>Grade Level:</strong></label>
                            <input type="number" name="grade_level" value={editData.grade_level} onChange={handleChange} />
                        </p>
                    </div>
                ) : (
                    <div>
                        <p><strong>ID:</strong> {student.primary_id}</p>
                        <p><strong>First Name:</strong> {student.first_name}</p>
                        <p><strong>Last Name:</strong> {student.last_name}</p>
                        <p><strong>Grade Level:</strong> {student.grade_level}</p>
                    </div>
                )}

                {/* Display Parents */}
                <h3>Parents</h3>
                {student.parents && student.parents.length > 0 ? (
                    <ul>
                        {student.parents.map(parent => (
                            <li key={parent.parent_id}>
                                {parent.first_name} {parent.last_name} ({parent.email})
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No parent information available.</p>
                )}

                {/* Display Courses */}
                <h3>Courses</h3>
                {student.courses && student.courses.length > 0 ? (
                    <ul>
                        {student.courses.map(course => (
                            <li key={course.course_id}>
                                {course.subject} - Period: {course.period}, Day: {course.day_of_week}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No course information available.</p>
                )}

                {/* Buttons for actions */}
                <div className="button-group">
                    {isEditing ? (
                        <>
                            <button onClick={handleUpdate}>Save Changes</button>
                            <button onClick={() => setIsEditing(false)}>Cancel</button>
                        </>
                    ) : (
                        <>
                            <button onClick={() => setIsEditing(true)}>Edit Student</button>
                            <button className="delete-button" onClick={handleDelete}>Delete Student</button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StudentDetails;
