import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './InstructorDetails.css';
import './TableStyles.css';

const InstructorDetails = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { id } = useParams();
    const [instructor, setInstructor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editCourse, setEditCourse] = useState({ course_id: '', subject: '' });
    const [isAdding, setIsAdding] = useState(false);
    const [newCourse, setNewCourse] = useState({
        course_id: '',
        instructor_id: id,
        subject: '',
        periods: [{ period: '', day_of_week: '' }],
        students: [{ student_id: '' }]
    });
    const [expandedCourse, setExpandedCourse] = useState(null);
    const [expandedPeriod, setExpandedPeriod] = useState(null);
    const [courseStudents, setCourseStudents] = useState({});

    useEffect(() => {
        const fetchInstructorDetails = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/instructors/${id}/details`);
                setInstructor(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Detailed error:", err);
                setError('Error fetching instructor details.');
                setLoading(false);
            }
        };

        fetchInstructorDetails();
    }, [id]);

    const handleAddCourse = async () => {
        if (!newCourse.course_id || !newCourse.instructor_id || !newCourse.subject) {
            alert('All fields (Course ID, Instructor ID, Subject) are required.');
            return;
        }

        try {
            await axios.post(`${apiUrl}/api/instructors/${id}/courses`, newCourse);

            // Add the new course directly to the instructor's course list in the state
            setInstructor((prevInstructor) => ({
                ...prevInstructor,
                courses: [
                    ...prevInstructor.courses,
                    {
                        ...newCourse,
                        periods: newCourse.periods.map(period => period.period).join(', '),
                        days: newCourse.periods.map(period => period.day_of_week).join(', ')
                    }
                ]
            }));

            // Reset newCourse form fields
            setNewCourse({
                course_id: '',
                instructor_id: id,
                subject: '',
                periods: [{ period: '', day_of_week: '' }],
                students: [{ student_id: '' }]
            });

            setIsAdding(false); // Close the add course form
            alert(`The course "${newCourse.subject}" has been successfully added.`);
        } catch (err) {
            console.error('Error adding course:', err);
            setError('Error adding course.');
        }
    };


    const handleNewCourseInputChange = (e) => {
        const { name, value } = e.target;
        setNewCourse({ ...newCourse, [name]: value });
    };

    const handleAddPeriod = () => {
        setNewCourse({
            ...newCourse,
            periods: [...newCourse.periods, { period: '', day_of_week: '' }]
        });
    };

    const handlePeriodChange = (index, name, value) => {
        const updatedPeriods = newCourse.periods.map((period, idx) =>
            idx === index ? { ...period, [name]: value } : period
        );
        setNewCourse({ ...newCourse, periods: updatedPeriods });
    };

    const handleAddStudent = () => {
        setNewCourse({
            ...newCourse,
            students: [...newCourse.students, { student_id: '' }]
        });
    };

    const handleStudentChange = (index, value) => {
        const updatedStudents = newCourse.students.map((student, idx) =>
            idx === index ? { student_id: value } : student
        );
        setNewCourse({ ...newCourse, students: updatedStudents });
    };

    const handleDeleteCourse = async (courseId, courseSubject) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete the course: "${courseSubject}"?`);
        if (!confirmDelete) return;

        try {
            await axios.delete(`${apiUrl}/api/courses/${courseId}`);
            setInstructor({
                ...instructor,
                courses: instructor.courses.filter(course => course.course_id !== courseId)
            });
            alert(`The course "${courseSubject}" has been successfully deleted.`);
        } catch (err) {
            setError('Error deleting course.');
        }
    };

    const handleEditCourseClick = (course) => {
        setEditCourse(course);
        setIsEditing(true);
    };

    const handleCourseInputChange = (event) => {
        setEditCourse({ ...editCourse, subject: event.target.value });
    };

    const handleUpdateCourse = async () => {
        try {
            await axios.put(`${apiUrl}/api/courses/${editCourse.course_id}`, {
                subject: editCourse.subject
            });

            setInstructor({
                ...instructor,
                courses: instructor.courses.map(course =>
                    course.course_id === editCourse.course_id ? { ...course, subject: editCourse.subject } : course
                )
            });
            setIsEditing(false);
            alert(`The course "${editCourse.subject}" has been successfully updated.`);
        } catch (err) {
            setError('Error updating course.');
        }
    };

    const toggleStudentList = async (courseId, period, day_of_week) => {
        const uniqueKey = `${courseId}-${period}-${day_of_week}`;

        if (expandedCourse === courseId && expandedPeriod === period) {
            setExpandedCourse(null);
            setExpandedPeriod(null);
        } else {
            try {
                const response = await axios.get(
                    `${apiUrl}/api/courses/${courseId}/period/${period}/students`,
                    { params: { day_of_week } }
                );

                setCourseStudents(prev => ({
                    ...prev,
                    [uniqueKey]: response.data
                }));

                setExpandedCourse(courseId);
                setExpandedPeriod(period);
            } catch (err) {
                console.error('Error fetching students:', err);
                setError('Error fetching students for the course.');
            }
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="instructor-details-container">
            {instructor ? (
                <div>
                    <h2>{instructor.first_name} {instructor.last_name}</h2>
                    <div className="instructor-info">
                        <p><strong>Username:</strong> <em>{instructor.user_name}</em></p>
                        <p><strong>Email:</strong> <em>{instructor.email}</em></p>
                    </div>

                    <h3>Courses</h3>
                    <ul>
                        {instructor.courses.map((course, index) => (
                            <li key={course.course_id} className="course-item">
                                <div
                                    onClick={() => setExpandedCourse(expandedCourse === course.course_id ? null : course.course_id)}
                                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                >
                                    {course.subject} (Periods: {course.periods})
                                </div>

                                {expandedCourse === course.course_id && (
                                    <ul>
                                        {course.periods.split(',').map((period, idx) => (
                                            <li key={`${course.course_id}-${period}-${idx}`}>
                                                Period: {period.trim()}, Day(s): {course.days.split(',').join(', ')}
                                                <button
                                                    onClick={() => toggleStudentList(course.course_id, period.trim(), course.days.split(',')[idx].trim())}
                                                >
                                                    {expandedCourse === course.course_id && expandedPeriod === period.trim() ? 'Hide Students' : 'Show Students'}
                                                </button>

                                                {expandedCourse === course.course_id && expandedPeriod === period.trim() && (
                                                    <ul className="student-list">
                                                        {courseStudents[`${course.course_id}-${period.trim()}-${course.days.split(',')[idx].trim()}`]?.length > 0 ? (
                                                            courseStudents[`${course.course_id}-${period.trim()}-${course.days.split(',')[idx].trim()}`].map(student => (
                                                                <li key={student.primary_id}>
                                                                    {student.first_name} {student.last_name} (Grade: {student.grade_level})
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <li>No students enrolled.</li>
                                                        )}
                                                    </ul>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                <div className="button-group">
                                    <button
                                        className="update-course-btn"
                                        onClick={() => handleEditCourseClick(course)}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="delete-course-btn"
                                        onClick={() => handleDeleteCourse(course.course_id, course.subject)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>

                    <button className="add-course-btn" onClick={() => setIsAdding(true)}>Add Course</button>

                    {isAdding && (
                        <div className="add-course-form">
                            <h3>Add New Course</h3>
                            <input
                                type="number"
                                name="course_id"
                                value={newCourse.course_id}
                                onChange={handleNewCourseInputChange}
                                placeholder="Enter Course ID"
                                required
                            />
                            <input
                                type="text"
                                name="subject"
                                value={newCourse.subject}
                                onChange={handleNewCourseInputChange}
                                placeholder="Enter Course Subject"
                                required
                            />

                            <h4>Periods</h4>
                            {newCourse.periods.map((period, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        placeholder="Period"
                                        value={period.period}
                                        onChange={(e) => handlePeriodChange(index, 'period', e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Day of Week"
                                        value={period.day_of_week}
                                        onChange={(e) => handlePeriodChange(index, 'day_of_week', e.target.value)}
                                    />
                                </div>
                            ))}
                            <button onClick={handleAddPeriod}>Add Period</button>

                            <h4>Students</h4>
                            {newCourse.students.map((student, index) => (
                                <div key={index}>
                                    <input
                                        type="text"
                                        placeholder="Student ID"
                                        value={student.student_id}
                                        onChange={(e) => handleStudentChange(index, e.target.value)}
                                    />
                                </div>
                            ))}
                            <button onClick={handleAddStudent}>Add Student</button>

                            <button onClick={handleAddCourse}>Add Course</button>
                            <button onClick={() => setIsAdding(false)}>Cancel</button>
                        </div>
                    )}

                    {isEditing && (
                        <div className="edit-course-form">
                            <h3>Update Course</h3>
                            <input
                                type="text"
                                value={editCourse.subject}
                                onChange={handleCourseInputChange}
                                placeholder="Enter new course name"
                            />
                            <button onClick={handleUpdateCourse}>Save Changes</button>
                            <button onClick={() => setIsEditing(false)}>Cancel</button>
                        </div>
                    )}

                    <h3>Events</h3>
                    <ul>
                        {instructor.events.map(event => (
                            <li key={event.event_id}>
                                <strong>{event.title}</strong> - <em>{new Date(event.event_date_time).toLocaleString()}</em>
                                <p className="event-description">{event.description}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>No instructor details available.</p>
            )}
        </div>
    );
};

export default InstructorDetails;
