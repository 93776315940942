import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making HTTP requests
import './CreateInstructor.css'; // Import your CSS for styling

/*
    CreateInstructor component is used to create a new instructor.
*/
const CreateInstructor = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();
    const [instructor, setInstructor] = useState({
        cognito_id: '',
        first_name: '',
        last_name: '',
        user_name: '',
        email: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInstructor(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting', instructor);

        // Make the POST request to the backend to create the instructor
        try {
            const response = await axios.post(`${apiUrl}/api/instructors`, instructor);
            console.log(response.data);
            alert('Instructor created successfully!');

            // Redirect to the instructors list after successful submission
            navigate('/admin/instructors');
        } catch (error) {
            console.error('Failed to create instructor:', error);
            alert('Failed to create instructor. Please check the console for details.');
        }
    };

    const handleClose = () => {
        navigate('/admin/instructors');
    };

    return (
        <div className='create-instructor' style={{ color: 'red', position: 'relative', padding: '30px' }}>
            {/* Close Button */}
            <button className="close-btn" onClick={handleClose}>X</button>
            <hr />
            <h2>Create New Instructor</h2>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-group">
                    <label htmlFor="instructorId" style={{ color: 'black' }}>Instructor ID:</label>
                    <input id="instructorId" name="cognito_id" value={instructor.cognito_id} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="firstName" style={{ color: 'black' }}>First Name:</label>
                    <input id="firstName" name='first_name' value={instructor.first_name} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName" style={{ color: 'black' }}>Last Name:</label>
                    <input id="lastName" name='last_name' value={instructor.last_name} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="userName" style={{ color: 'black' }}>Username:</label>
                    <input id="userName" name='user_name' value={instructor.user_name} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="email" style={{ color: 'black' }}>Email:</label>
                    <input id="email" type="email" name='email' value={instructor.email} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <button type="submit" className="submit-btn" style={{ color: 'black' }}>Create Instructor</button>
            </form>
        </div>

    );
}

export default CreateInstructor;
