import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making HTTP requests
import './CreateStudent.css'; // Import your CSS for styling

/*
    CreateStudent component is used to create a new student.
*/
const CreateStudent = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();
    const [student, setStudent] = useState({
        primary_id: '',
        first_name: '',
        last_name: '',
        grade_level: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStudent(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleClose = () => {
        navigate('/admin/courses');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting', student);

        // Make the POST request to the backend to create the student
        try {
            const response = await axios.post(`${apiUrl}/api/students`, student);
            console.log(response.data);
            alert('Student created successfully!');

            // Redirect to the students list after successful submission
            navigate('/admin/students');
        } catch (error) {
            console.error('Failed to create student:', error);
            alert('Failed to create student. Please check the console for details.');
        }
    };

    return (
        <div className='create-student' style={{ color: 'red', position: 'relative', padding: '30px' }}>
            {/* Close Button */}
            <button className="close-btn" onClick={handleClose}>X</button>

            <hr />
            <h2>Create New Student</h2>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-group">
                    <label htmlFor="studentId" style={{ color: 'black' }}>Student ID:</label>
                    <input id="studentId" name="primary_id" type="number" value={student.primary_id} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="firstName" style={{ color: 'black' }}>First Name:</label>
                    <input id="firstName" name='first_name' value={student.first_name} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="lastName" style={{ color: 'black' }}>Last Name:</label>
                    <input id="lastName" name='last_name' value={student.last_name} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="gradeLevel" style={{ color: 'black' }}>Grade Level:</label>
                    <input id="gradeLevel" name='grade_level' type="number" value={student.grade_level} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <button type="submit" className="submit-btn" style={{ color: 'black' }}>Create Student</button>
            </form>
        </div>

    );
}

export default CreateStudent;
