import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaEnvelope, FaCog } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import InstructorHomeStyles from './styles/InstructorHomeStyles';
import InstructorSettings from './InstructorSettings';

const InstructorLayout = ({ children }) => {
    const navigate = useNavigate();
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const settingsRef = useRef(null);

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };

    const closeSettings = (e) => {
        if (settingsRef.current && !settingsRef.current.contains(e.target)) {
            setIsSettingsOpen(false);
        }
    };

    const iconStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '12px' };

    return (
        <div style={{ position: 'relative' }} onClick={isSettingsOpen ? closeSettings : null}>
            <div style={{ minHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}>
                {children}
            </div>
            <div style={InstructorHomeStyles.bottomBar}>
                <div style={iconStyle} onClick={() => navigate('/instructor_home')}>
                    <FaHome size="1.5em" />
                    <span>Home</span>
                </div>
                <div style={iconStyle} onClick={() => navigate('/instructor-calendar')}>
                    <BsCalendar size="1.5em" />
                    <span>Calendar</span>
                </div>
                <div style={iconStyle} onClick={() => navigate('/messagepage')}>
                    <FaEnvelope size="1.5em" />
                    <span>Messages</span>
                </div>
                <div style={iconStyle} onClick={toggleSettings}>
                    <FaCog size="1.5em" />
                    <span>Settings</span>
                </div>
            </div>

            {/* Settings Module */}
            {isSettingsOpen && (
                <div ref={settingsRef} style={{ zIndex: 1000 }}>
                    <InstructorSettings toggleSettings={toggleSettings} />
                </div>
            )}
        </div>
    );
};

export default InstructorLayout;
