import img from '../../../imgs/background.jpg'

const instructorCourseStyles = {
    background: {
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
        height: '100vh',
        backgroundImage: 'url(' + require('../../../imgs/background.jpg') + ')'
    },
    classInfo: {
        color: 'white',
        fontSize: '13px',
    },
    container: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Ensures calendar is centered
        minHeight: '100vh',  // Full height view for better visual appearance
    },
    header: {
        alignSelf: 'flex-start',
        color: '#444', // Using a slightly darker shade for better readability
        
    },
    studentCard: {
        background: '#f0f0f0', // Light grey background for each student card
        padding: '15px', // Padding inside each student card
        marginBottom: '15px', // Space between each card
        borderRadius: '8px', // Rounded corners
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow for 3D effect
    },
    performanceIndicator: {
        display: 'flex',
        flexWrap: 'wrap', // Allows items to wrap to the next line
        justifyContent: 'space-around', // Even spacing around each item
        alignItems: 'center',
        marginTop: '10px',
        gap: '20px', // Space between each period and its icons
    },
    periodItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '5px',  // Adds space around each period item
        minWidth: '45px', // Ensures that each item has at least some minimum width
    },
    iconRow: { // Add this for direct icon row control
        display: 'flex',
        justifyContent: 'center',
        gap: '5px', // Space between icons
    },
    newsSection: {
        marginTop: '100px', // Top margin from the last student card
        marginBottom: '100px',
        padding: '15px', // Padding inside the news section
        background: '#f0f0f0',
        borderRadius: '8px', // Rounded corners for the news section
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Consistent shadow as the cards
        fontSize: '16px', // Font size for news content
    }
};

export default instructorCourseStyles;
