import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AdminCalendar from './AdminCalendar';
import './EventsTable.css';

const EventsList = () => {
    const [events, setEvents] = useState([]);
    const [selectedDateEvents, setSelectedDateEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [editData, setEditData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = () => {
        axios.get('http://localhost:5000/api/events')
            .then(response => {
                setEvents(response.data);
            })
            .catch(error => {
                console.error('Error fetching events:', error);
            });
    };

    const handleDateClick = (date) => {
        // Filter events based on the selected date
        const eventsForDate = events.filter(event =>
            new Date(event.event_date_time).toDateString() === date.toDateString()
        );
        setSelectedDateEvents(eventsForDate);
        setSelectedEvent(null); // Reset selected event
    };

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setEditData({ ...event });
    };

    return (
        <div className="events-calendar">
            <h2 style={{ color: '#FFFFFF', fontSize: 50, textAlign: 'center', position: 'relative', top: '-50px' }}>Events Calendar</h2>
            <AdminCalendar events={events} onDateClick={handleDateClick} />

            <div className="event-details">
                {selectedDateEvents.length > 0 && (
                    <div>
                        <h3>Events on {new Date(selectedDateEvents[0].event_date_time).toDateString()}</h3>
                        <ul>
                            {selectedDateEvents.map(event => (
                                <li key={event.event_id} onClick={() => handleEventClick(event)}>
                                    {event.title} - {event.category}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <button
                className="add-event-button"
                onClick={() => navigate('/admin/event/create')}
                style={{
                    position: 'absolute',
                    bottom: '-60px',
                    left: '50%', 
                    transform: 'translateX(-50%)',
                    padding: '12px 24px', 
                    backgroundColor: '#007bff',
                    color: 'white', 
                    fontSize: '18px', 
                }}
            >
                Add Event
            </button>
        </div>
    );
};

export default EventsList;
