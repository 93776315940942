import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CourseDetails.css';  // Assuming you have this CSS file

const CourseDetails = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { courseId } = useParams();  // Get the course ID from the URL
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false); // To toggle edit mode
    const [editData, setEditData] = useState({});
    const navigate = useNavigate();

    // Fetch the course details
    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/courses/${courseId}`);
                console.log('Course Details Fetched:', response.data);  // Check what data is returned
                setCourse(response.data);
                setEditData({
                    subject: response.data.subject,
                    instructor_id: response.data.instructor_id,
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching course details:', error);
                setError('Failed to load course details.');
                setLoading(false);
            }
        };

        fetchCourseDetails();
    }, [courseId]);


    const handleEditToggle = () => {
        setEditMode(!editMode);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleUpdate = async () => {
        try {
            await axios.put(`${apiUrl}/api/courses/${courseId}`, editData);
            setCourse({
                ...course,
                subject: editData.subject,
                instructor_id: editData.instructor_id,
            });
            setEditMode(false);
            alert('Course updated successfully.');
        } catch (error) {
            console.error('Error updating course:', error);
            setError('Failed to update course.');
        }
    };

    const handleClose = () => {
        navigate('/admin/courses');
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete this course?');
        if (!confirmDelete) return;

        try {
            await axios.delete(`${apiUrl}/api/courses/${courseId}`);
            alert('Course deleted successfully.');
            navigate('/admin/courses');
        } catch (error) {
            console.error('Error deleting course:', error);
            setError('Failed to delete course.');
        }
    };

    if (loading) return <p>Loading course details...</p>;
    if (error) return <p>{error}</p>;
    if (!course) return <p>No course details available.</p>;

    return (
        <div className="course-details-container" style={{ color: 'red', position: 'relative', padding: '30px' }}>
            {/* Close Button */}
            <button className="close-btn" onClick={handleClose}>X</button>
            <h2>Course Details: {course.subject}</h2>

            {editMode ? (
                <div>
                    <label>Subject: </label>
                    <input
                        type="text"
                        name="subject"
                        value={editData.subject}
                        onChange={handleInputChange}
                    />
                    <label>Instructor ID: </label>
                    <input
                        type="number"
                        name="instructor_id"
                        value={editData.instructor_id}
                        onChange={handleInputChange}
                    />
                    <button onClick={handleUpdate}>Save</button>
                    <button onClick={handleEditToggle}>Cancel</button>
                </div>
            ) : (
                <div>
                    <p><strong>Course ID:</strong> {course.course_id}</p>
                    <p><strong>Instructor:</strong>
                        {course.instructor ? `${course.instructor.first_name} ${course.instructor.last_name}` : "No instructor assigned"}
                    </p>

                    {/* Display Periods */}
                    <h3>Periods</h3>
                    {course.schedule && course.schedule.length > 0 ? (
                        <ul>
                            {course.schedule.map((period, index) => (
                                <li key={index}>
                                    Period: {period.period}, Day: {period.day_of_week}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No period information available.</p>
                    )}

                    {/* Display Students */}
                    <h3>Enrolled Students</h3>
                    {course.students && course.students.length > 0 ? (
                        <ul>
                            {course.students.map((student) => (
                                <li key={student.primary_id}>
                                    {student.first_name} {student.last_name} (Grade: {student.grade_level})
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No students enrolled in this course.</p>
                    )}

                    <button onClick={handleEditToggle}>Edit</button>
                    <button onClick={handleDelete} className="delete">Delete</button>
                </div>
            )}
        </div>
    );
};

export default CourseDetails;
