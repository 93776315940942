// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Account } from './UserAuth/Account';
import Status from './UserAuth/Status';
import CourseDetails from './Admin/Courses/CourseDetails';
import ParentHome from "./Parent/screens/ParentHome";
import ParentMain from './Parent/screens/ParentMain';
import ParentCalendar from './Parent/screens/ParentCalendar';
import InstructorHome from "./Instructor/screens/InstructorHome";
import InstructorsList from './Admin/Instructors/InstructorsList';
import PeriodDetails from './Instructor/screens/PeriodDetails';
import InstructorStudents from "./Instructor/screens/InstructorStudents";
import AdminHome from './Admin/pages/AdminHome';
import ManageStudents from './Admin/Students/ManageStudents';
import ManageInstructors from './Admin/Instructors/ManageInstructors';
import ManageEvents from './Admin/Events/ManageEvents';
import ManageParents from './Admin/Parents/ManageParents';
import CreateStudent from './Admin/Students/CreateStudent';
import CreateParent from './Admin/Parents/CreateParent';
import CreateInstructor from './Admin/Instructors/CreateInstructor';
import MessagePage from './Messages/Screens/MessagePage';
import AdminCalendar from './Admin/Events/AdminCalendar';
import ManageCourses from './Admin/Courses/ManageCourses';
import InstructorCourse from './Instructor/screens/InstructorCourse';
import InstructorCalendar from './Instructor/screens/TeacherCalendar';
import CreateCourse from './Admin/Courses/CreateCourse';
import InstructorDetails from './Admin/Instructors/InstructorDetails';
import ParentDetails from './Admin/Parents/ParentDetails';
import Login from './Login/screens/Login';
import StudentDetails from './Admin/Students/StudentDetails';
import EventsPage from './Admin/Events/EventsPage';
import CreateEvent from './Admin/Events/CreateEvent';
import EventDetails from './Admin/Events/EventDetails';
import EventsList from './Admin/Events/EventsList';
import Forbidden from './UserAuth/Forbidden';
import './App.css'; // Ensure App.css is imported for global styles
import CourseSchedule from './Admin/Courses/CourseSchedule';


const App = () => {
    return (
        <div className="app-container">
            <Account>
                <BrowserRouter>
                    <Routes>
                        {/* Public Routes */}
                        <Route path="/" element={
                            <>
                                <Login />  {/* Display login form */}
                            </>
                        } />

                        {/* Parent Routes */}
                        <Route path="/parent-home" element={<ParentHome />} />
                        <Route path="/parent-main/:studentId" element={<ParentMain />} />
                        <Route path="/parent-calendar" element={<ParentCalendar />} />

                        {/* Instructor Routes */}
                        <Route path="/instructor_home" element={<InstructorHome />} />
                        <Route path="/instructors-list" element={<InstructorsList />} />
                        <Route path="/instructor-calendar" element={<InstructorCalendar />} />
                        <Route path="/instructor_course/:courseCode" element={<InstructorCourse />} />
                        <Route path="/instructor_course/:courseName/:studentName" element={<InstructorStudents />} />
                        <Route path="/period-details/:periodId" element={<PeriodDetails />} />

                        {/* Admin Routes */}
                        <Route path="/admin/home" element={<AdminHome />} />
                        <Route path="/admin/students" element={<ManageStudents />} />
                        <Route path="/admin/parents" element={<ManageParents />} />
                        <Route path="/admin/parent/:id" element={<ParentDetails />} />
                        <Route path="/admin/instructors" element={<ManageInstructors />} />
                        <Route path="/admin/events" element={<ManageEvents />} />
                        <Route path="/admin/courses" element={<ManageCourses />} />
                        <Route path="/admin/instructor/:id" element={<InstructorDetails />} />
                        <Route path="/admin/course/create" element={<CreateCourse />} />
                        <Route path="/admin/student/create" element={<CreateStudent />} />
                        <Route path="/admin/instructor/create" element={<CreateInstructor />} />
                        <Route path="/admin/parent/create" element={<CreateParent />} />
                        <Route path="/admin/events" element={<AdminCalendar />} />
                        <Route path="/admin/messages" element={<MessagePage />} />
                        <Route path="/admin/student/:studentId" element={<StudentDetails />} />
                        <Route path="/admin/course/:courseId" element={<CourseDetails />} />
                        <Route path="/admin/events" element={<AdminCalendar />} />
                        <Route path="/admin/event/:id" element={<EventDetails />} />
                        <Route path="/admin/event/create" element={<CreateEvent />} />
                        <Route path="/admin/event" element={<EventsList />} />
                        <Route path="home" element={<AdminHome />} />
                        <Route path="/admin/course/schedule" element={<CourseSchedule />} />


                        {/* Other Routes */}
                        <Route path="/messagepage" element={<MessagePage />} />
                        <Route path="/forbidden" element={<Forbidden />} />
                    </Routes>
                </BrowserRouter>
            </Account>
        </div>
    );
};

export default App;
