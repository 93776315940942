const instructorHomeStyles = {
    container: {
        padding: '20px',
        minHeight: 'calc(100vh - 70px)',
        overflowY: 'auto',
        paddingBottom: '0px'
    },
    welcomeText: {
        color: 'white',
    },
    periodsContainer: {
        display: 'flex',
        flexDirection: 'column', // Stack periods vertically
        gap: '20px', // Spacing between each period tab
        marginBottom: '20px',
        /*maxHeight: '400px', // 250px is the height of the fixed bottom section
        overflowY: 'auto',
        padding: '15px',*/
    },
    periodCard: {
        backgroundColor: '#f0f0f0',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        marginBottom: '10px'
    },
    periodItem: {
        fontSize: '18px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
        cursor: 'pointer', // Indicate it's clickable
        backgroundColor: 'lightgrey', // Light background for the tab
        ':hover': {
            backgroundColor: '#f0f0f0', // Darker background on hover
        },
        transition: 'background-color 0.3s', // Smooth transition for hover effect
    },
    newsSection: {
        padding: '20px',
        backgroundColor: '#f4f4ff',  // Light blue background
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow for the section
        marginTop: '15px',
        marginBottom: '10px', // To ensure it doesn't touch the bottom bar
        color: '#000', // Text color
        textAlign: 'left', // Align text to the left
    },
    newsSectionHeader: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    bottomBar: {
        height: '45px', // Increased height to accommodate text
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        background: '#ddd',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        boxShadow: '0 -1px 5px rgba(0,0,0,0.2)',
        padding: '20px 0' // Padding for visual balance
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
        fontSize: '1.25em',
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: '#fff',
        borderBottom: '1px solid #ddd',
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    menuIcon: {
        cursor: 'pointer',
        fontSize: '24px',
    },
    scrollablePeriodsContainer: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 250px - 50px)', // Header is assumed to be 50px
        padding: '40px',
        borderBottom: '1px solid #ddd',
    },
    divider: {
        borderColor: '#d3d3d3',
        marginTop: '50px',
        // any other styles for the divider line
    },
    lowerSections: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '0px', // Adds space between this section and the above elements
        padding: '20px',
    },
    calendarSection: {
        flex: 1, // Calendar takes up 2/3 of the space
        padding: '5px',
        border: '1px solid #d3d3d3', // Mockup border to visualize the section
        minHeight: '100px', // Minimum height for visual purposes
        marginRight: '10px', // Adds a bit of margin between the calendar and news section
    },
    calendarGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)', // Creates 7 columns, one for each day of the week
        gridGap: '5px', // Spacing between cells
        marginTop: '5px', // Space above the grid
    },
    calendarIcon: {
        fontSize: '1.5rem',
        marginBottom: '10px',
    },
    monthNavigation: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    navButton: {
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        fontSize: '1em',
    },
    monthLabel: {
        fontSize: '1.5em',
        fontWeight: 'bold',
    },
    calendarDay: {
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #eaeaea',
        backgroundColor: '#fafafa',
    },
    calendarDayCurrent: {
        backgroundColor: 'lightgrey',
        color: 'white',
        padding: '5px',
        textAlign: 'center',
    },
    calendarDayWithEvent: {
        minHeight: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #eaeaea',
        backgroundColor: 'lightgreen', // Different background to indicate an event
        position: 'relative',
    },
    calendarDayActive: {
        backgroundColor: 'lightblue',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
    },
    eventBubble: {
        position: 'absolute',
        top: '0px', // default position, will be dynamically updated
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 100, // Make sure it is above all other elements
        minWidth: '200px', // Minimum width to prevent text wrapping, adjust as needed
        whiteSpace: 'normal', // This should allow text wrapping, which is good.
        overflow: 'visible', // Ensure this is visible so content is not hidden.
        textOverflow: 'clip', // This will clip the text which is okay.
        color: 'black',

    },
    addEventForm: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        marginTop: '0px',
    },
    dateInput: {
        padding: '10px',
        fontSize: '1rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        width: '150px',
    },
    eventInput: {
        padding: '10px',
        fontSize: '1rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        flexGrow: 1,
        marginRight: '10px',
    },
    addButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        fontSize: '1rem',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
};

export default instructorHomeStyles;