import React from 'react';
import { messagePreviewStyles } from '../styles/Components/MessagePreviewStyles';

const MessagePreview = ({ message, onClick, deleteMessage }) => {
    const { subject, sender_name, recipient_name, message_date_time } = message;

    return (
        <div style={messagePreviewStyles.container}>
            {/* Left side: Subject line, From, and To */}
            <div style={messagePreviewStyles.left} onClick={onClick}>
                <div>
                    <strong>Subject:</strong> {subject}
                </div>
                <div>
                    <strong>From:</strong> {sender_name}
                </div>
                <div>
                    <strong>To:</strong> {recipient_name}
                </div>
            </div>

            {/* Right side: Date and Delete button */}
            <div style={messagePreviewStyles.right}>
                <div>{new Date(message_date_time).toLocaleString()}</div>

                {/* Only render the delete button if the deleteMessage function is passed */}
                {deleteMessage && (
                    <button
                        onClick={deleteMessage} // Trigger the deleteMessage handler passed from parent
                        style={{
                            padding: '5px',
                            fontSize: '12px',
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                        }}
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
};

export default MessagePreview;
