import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../UserAuth/Account';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const { authenticate, logout, getSession } = useContext(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        logout();
    }, [logout]);

    const onSubmit = async (event) => {
        event.preventDefault();

        try {
            // Authenticate the user
            await authenticate(email, password);

            // After authentication, retrieve the session and user group (roles)
            const { userGroups } = await getSession();

            // Navigate based on the user's role
            if (userGroups && userGroups.includes('Admin')) {
                navigate('/admin/home');
            } else if (userGroups && userGroups.includes('Parents')) {
                navigate('/parent-home');
            } else if (userGroups && userGroups.includes('Instructors')) {
                navigate('/instructor_home');
            } else {
                // Navigate to unauthorized error page for unrecognized or unauthorized groups
                navigate('/unauthorized');
            }

        } catch (err) {
            window.alert('Login failed: Incorrect username or password. Please try again.');
        }
    };

    return (
        <div>
            <form onSubmit={onSubmit} className="login__form">
                <label htmlFor="email" className="login__label">Username</label>
                <input
                    id="email"
                    className="login__input"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    required
                    placeholder="Enter your username"
                />
                <label htmlFor="password" className="login__label">Password</label>
                <input
                    id="password"
                    className="login__input"
                    value={password}
                    type={showPassword ? "text" : "password"} // Toggle type between "text" and "password"
                    onChange={event => setPassword(event.target.value)}
                    required
                    placeholder="Enter your password"
                />
                <div className="login__checkbox">
                    <input
                        type="checkbox"
                        id="showPassword"
                        checked={showPassword}
                        onChange={() => setShowPassword(!showPassword)}
                    />
                    <label htmlFor="showPassword">Show Password</label>
                </div>
                <button type="submit" className="login__button">Login</button>
            </form>
        </div>
    );
};

export default Login;
