// Inside MessagePreviewStyles.js

export const messagePreviewStyles = {
    container: {
        display: 'flex',               // Flex container to align left and right sections
        justifyContent: 'space-between', // Distribute space evenly between left and right sections
        marginBottom: '20px',
        border: '2px solid #000',
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',

        marginLeft: '12%',          // Adjust margin for left container (12% to avoid overlap)
        width: 'calc(100% - 12%)',  // Adjust width based on margin

        wordWrap: 'break-word',      // Ensure long words break
        boxSizing: 'border-box',     // Account for padding and borders in width/height calculations
        overflow: 'visible',        // Allow content to overflow without clipping
        flexWrap: 'wrap',            // Allow wrapping of content if needed
    },
    left: {
        flex: 1,                    // Allow left side to take up available space
        marginRight: '10px',        // Space between left and right
        wordWrap: 'break-word',     // Prevent long words from overflowing
        overflow: 'visible',        // Allow content to overflow
    },
    right: {
        display: 'flex',             // Flexbox layout for the right section
        alignItems: 'center',        // Vertically center items in the right section
        justifyContent: 'flex-end',  // Right-align the date and button
        gap: '10px',                 // Space between date and delete button
        minWidth: '150px',           // Minimum width for the right section to prevent squashing
        flexShrink: 0,               // Prevent right section from shrinking
        whiteSpace: 'nowrap',        // Prevent text wrapping in the date section
        overflow: 'visible',         // Ensure that overflow content is visible
    },

    // Media query for small screens (mobile)
    '@media (max-width: 600px)': {
        container: {
            flexDirection: 'column',   // Stack the left and right sections vertically
            alignItems: 'flex-start',  // Align left section to the start (left-aligned)
            justifyContent: 'flex-start',  // Align everything to the top
        },
        left: {
            marginRight: '0',   // Remove margin to avoid extra space on smaller screens
            marginBottom: '10px', // Add some space between left section and right section on small screens
        },
        right: {
            marginTop: '10px',   // Add margin to the top for space between content
            justifyContent: 'flex-start', // Align items to the left on mobile
            minWidth: 'auto',     // Let the right section adjust width dynamically
            width: '100%',        // Take up the full width on mobile for the date and button
        },
    },
};

// Add hover effect
messagePreviewStyles.container[':hover'] = {
    backgroundColor: '#f0f0f0', // Change background color on hover
};

// Adjust delete button margin to move it slightly to the left
export const deleteButtonStyles = {
    marginLeft: '-5px', // Adjust to move button to the left
    padding: '5px',
    fontSize: '12px',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
};

export default messagePreviewStyles;
