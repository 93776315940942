const styles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        padding: '10px',
        cursor: 'pointer',
        color: '#007bff',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    backIcon: {
        fontSize: '20px',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50%',
        textAlign: 'center',
    },
    header: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#ffffff', // Bright white for student name
    },
    studentName: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#ffffff', // Bright white for name
    },
    studentDetails: {
        fontSize: '16px',
        color: '#dddddd', // Brighter shade of gray for student ID, grade, and date
    },
    tableContainer: {
        height: '50%',
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        marginBottom: '150px', // Add bottom margin for the dashboard
    },
    table: {
        width: '90%',
        maxWidth: '1200px',
        borderCollapse: 'collapse',
    },
    tableHeader: {
        backgroundColor: '#333333', // Dark background for table headers
        fontWeight: 'bold',
        padding: '10px',
        textAlign: 'center',
        fontSize: '16px',
        border: '1px solid #ddd',
        color: '#ffffff', // White text in header
    },
    tableCell: {
        padding: '8px',
        textAlign: 'center',
        border: '1px solid #ddd',
        fontSize: '14px',
    },
    periodCell: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        color: '#000000', // Lighter white for Period numbers
    },
    noteIcon: {
        cursor: 'pointer',
        color: '#007bff',
        fontSize: '18px',
    },
    icon: {
        fontSize: '30px',
        display: 'block',
        margin: '0 auto',
    },
    errorMessage: {
        color: '#ff0000',
        textAlign: 'center',
        fontWeight: 'bold',
    },
};

export default styles;
