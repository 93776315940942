import React from 'react';

const Forbidden = () => {
    return (
        <div>
            <h1>403 Forbidden</h1>
            <p>You do not have permission to access this resource. Please contact your administrator if you believe this is an error.</p>
        </div>
    );
};

export default Forbidden;
