import React from 'react';
import { useParams } from 'react-router-dom';
import instructorHomeStyles from './styles/InstructorHomeStyles';
import { IoAccessibilitySharp, IoPodium } from 'react-icons/io5';

const InstructorStudents = () => {
    const { className, studentName } = useParams();

    // Temporary data
    const studentDetails = [
        { date: '04/12/2024', attendance: 'present', performance: 'good' },
        { date: '04-11/2024', attendance: 'absent', performance: 'poor' },
    ];

    // Function to map numeric values to colors 
    const getAttendanceColor = (value) => {
        switch (value) {
            case 'absent':
                return 'red';
            case 'present':
                return 'green';
            default:
                return 'gray'; // Or any default color
        }
    };

    // Function to map numeric values to colors 
    const getPerformanceColor = (value) => {
        switch (value) {
            case 'poor':
                return 'red';
            case 'good':
                return 'orange';
            case 'excellent':
                return 'green';
            default:
                return 'gray'; // Or any default color
        }
    };

    return (
        <div>
            <h1 style={instructorHomeStyles.heading}>{studentName} - {className}</h1>
            <table style={instructorHomeStyles.table}>
                <thead>
                    <tr>
                        <th style={instructorHomeStyles.tableHeaderCell}>Date</th>
                        <th style={instructorHomeStyles.tableHeaderCell}>Attendance</th>
                        <th style={instructorHomeStyles.tableHeaderCell}>Performance</th>
                    </tr>
                </thead>
                <tbody>
                    {studentDetails.map((record, index) => (
                        <tr key={index}>
                            <td style={instructorHomeStyles.tableCell}>{record.date}</td>
                            <td style={instructorHomeStyles.tableCell}>
                                {/* Render icon with color based on attendance */}
                                <IoAccessibilitySharp color={getAttendanceColor(record.attendance)} />
                            </td>
                            <td style={instructorHomeStyles.tableCell}>
                                {/* Render icon with color based on performance */}
                                <IoPodium color={getPerformanceColor(record.performance)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default InstructorStudents;
