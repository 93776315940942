import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CoursesList from './CoursesList';
import axios from 'axios';

const ManageCourses = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/courses`);
                setCourses(response.data);
            } catch (error) {
                console.error('Failed to fetch courses:', error);
            }
        };

        fetchCourses();
    }, []);

    const handleDelete = async (course_id) => {
        try {
            await axios.delete(`${apiUrl}/api/courses/${course_id}`);
            setCourses(courses.filter(course => course.course_id !== course_id));
        } catch (error) {
            console.error('Failed to delete course:', error);
        }
    };

    const handleClickCreate = () => {
        navigate('/admin/course/create');
    };

    return (
        <>
            <CoursesList courses={courses} onDelete={handleDelete} onCreate={handleClickCreate} />
        </>
    );
};

export default ManageCourses;
