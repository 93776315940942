// Existing imports
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InstructorLayout from '../screens/InstructorLayout';
import './styles/InstructorNotesStyles.css';
import { FaRegSmile, FaRegSadCry, FaRegMeh, FaBook, FaUserCheck, FaArrowLeft, FaArrowRight, FaCalendarAlt, FaRegStickyNote } from 'react-icons/fa';
import instructorCourseStyles from './styles/InstructorCourseStyles';
import Select from 'react-select';
import axios from 'axios';
import { AccountContext } from '../../UserAuth/Account';

const InstructorCourse = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const location = useLocation();
    const navigate = useNavigate();
    const { classCode, period } = location.state || {};
    const [students, setStudents] = useState([]);
    const [ratings, setRatings] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [draggedStudent, setDraggedStudent] = useState(null);
    const [isSeatView, setIsSeatView] = useState(false);
    const [editNotes, setEditNotes] = useState(null); // Track which student is being edited
    const [noteText, setNoteText] = useState(''); // Track the text for the notes
    const [showNotes, setShowNotes] = useState(false);
    const [studentNotes, setStudentNotes] = useState('');
    const [initialDragPosition, setInitialDragPosition] = useState({ x: 0, y: 0 });
    const [editingStudentId, setEditingStudentId] = useState(null);
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const { userGroups } = await getSession();
                // Check if the user belongs to the "Parents" user group
                if (!(userGroups && userGroups.includes('Instructors'))) {
                    navigate('/forbidden');  // Redirect to Forbidden page
                    return;
                }

            } catch (error) {
                console.error('Error checking authentication:', error);
                navigate('/'); // Redirect to login on error
            }
        };

        checkAuthentication();

        // Fetch students and their ratings in the course and period for the selected date
        const formattedDate = formatDate(selectedDate); // Directly use formatDate without UTC adjustments
        axios.get(`${apiUrl}/api/courses/${classCode}/period/${period}/students/ratings?date=${formattedDate}`)
            .then(response => {
                const studentData = response.data;

                // Filter out duplicate students based on primary_id
                const uniqueStudents = studentData.filter(
                    (student, index, self) =>
                        index === self.findIndex((s) => s.primary_id === student.primary_id)
                );

                setStudents(uniqueStudents);

                const initialRatings = {};
                uniqueStudents.forEach(student => {
                    initialRatings[student.primary_id] = {
                        academic: student.academic || '',
                        behavior: student.behavior || '',
                        attendance: student.attendance || '',
                        //notes: student.notes || '' // Populate notes for each student
                    };
                });
                setRatings(initialRatings);
            })
            .catch(error => console.error('Error fetching students and ratings:', error));
    }, [classCode, period, selectedDate, getSession, navigate]);


    const handleIconClick = (studentId, category, rating) => {
        // Determine the new rating value based on the current state (toggle or clear)
        const currentRating = ratings[studentId]?.[category];
        const newRating = currentRating === rating ? undefined : rating;

        // Send the specific rating update to the backend
        axios.post(`${apiUrl}/api/students/${studentId}/ratings`, {
            course_id: classCode,
            period: period,
            rating_date: formatDate(selectedDate),
            [category]: newRating, // Send only the updated category
        })
            .then(response => {
                console.log(`Successfully updated ${category} rating for student ${studentId}`);

                // Update the local state with the new rating after successful API call
                setRatings(prevRatings => {
                    // Directly replace the student's rating with the new one
                    return {
                        ...prevRatings,
                        [studentId]: {
                            ...prevRatings[studentId],
                            [category]: newRating // Update only the specific category
                        }
                    };
                });
            })
            .catch(error => {
                console.error(`Error updating ${category} rating for student ${studentId}:`, error);
            });
    };

    const handleNotesClick = async (studentId) => {
        const formattedDate = formatDate(selectedDate);
        try {
            const response = await axios.get(`/api/courses/${classCode}/period/${period}/student/${studentId}/notes`, {
                params: { date: formattedDate } // Pass formatted date as a query parameter
            });

            // Check if notes are available
            if (response.data) {
                setStudentNotes(response.data);
                setShowNotes(true);
            } else {
                alert('No notes available for this student.');
            }
        } catch (error) {
            console.error('Error fetching notes:', error);
            alert('An error occurred while fetching notes. Please try again later.');
        }
    };

    const isActive = (studentId, category, rating) => {
        return ratings[studentId]?.[category] === rating;
    };

    const renderIcon = (IconComponent, studentId, category, rating, color) => {
        const active = isActive(studentId, category, rating);
        return (
            <IconComponent
                style={{
                    cursor: 'pointer',
                    color: active ? color : 'lightgrey',
                    margin: '5px',
                    fontSize: '26px',
                }}
                onClick={() => handleIconClick(studentId, category, rating)}
            />
        );
    };

// Icons and ratings mapping
    const icons = {
        academic: <FaBook />,
        behavior: <FaRegSmile />,
        attendance: <FaUserCheck />,
    };

    const colors = {
        excellent: 'green',
        average: 'orange',
        needs_improvement: 'red',
        present: 'green',
        tardy: 'orange',
        absent: 'red',
    };

    const ratingsOptions = {
        academic: ['excellent', 'average', 'needs_improvement'],
        behavior: ['excellent', 'average', 'needs_improvement'],
        attendance: ['present', 'tardy', 'absent']
    };

// Custom Option component for react-select
    const CustomOption = (props) => {
        const { data, innerRef, innerProps, isSelected } = props;

        // Set the background color and text color based on the selection state
        const backgroundColor = isSelected ? 'lightgray' : 'white';
        const color = data.color;  // Use the specific color for the option

        return (
            <div
                ref={innerRef}
                {...innerProps}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor,  // Apply background color based on selection
                    color,  // Apply text color based on rating color
                    padding: '5px',  // Adjust padding for icons
                    border: 'none',  // Remove any border
                    cursor: 'pointer',
                    position: 'relative', // Ensure proper positioning
                }}
            >
                {/* Render the icon with the color for each category */}
                {icons[data.category]}
            </div>
        );
    };

    const renderDropdown = (studentId, category) => {
        const currentRating = ratings[studentId]?.[category];

        // Define the options for each category with their respective icons
        const options = ratingsOptions[category].map(option => ({
            value: option,
            label: option,
            category: category, // Pass category information so we know which icon to show
            color: colors[option] || 'lightgrey',  // Set the color based on the rating
        }));

        const handleDropdownChange = (selectedOption) => {
            const newRating = selectedOption.value;

            // Send the new rating to the backend
            axios.post(`${apiUrl}/api/students/${studentId}/ratings`, {
                course_id: classCode,
                period: period,
                rating_date: formatDate(selectedDate),
                [category]: newRating,
            })
                .then(response => {
                    console.log(`Successfully updated ${category} rating for student ${studentId}`);

                    // Update the local state with the new rating after successful API call
                    setRatings(prevRatings => ({
                        ...prevRatings,
                        [studentId]: {
                            ...prevRatings[studentId],
                            [category]: newRating // Update only the specific category
                        }
                    }));
                })
                .catch(error => {
                    console.error(`Error updating ${category} rating for student ${studentId}:`, error);
                });
        };

        return (
            <div style={{ width: '50px' }}>
                {/* Dropdown for each category */}
                <Select
                    options={options}
                    value={options.find(option => option.value === currentRating) || null}
                    onChange={handleDropdownChange}
                    getOptionLabel={(e) => (
                        <div style={{ display: 'flex', alignItems: 'center', color: e.color }}>
                            {icons[e.category]} {/* Display only the icon */}
                        </div>
                    )}
                    components={{ Option: CustomOption }} // Use custom option to display only icons inside the dropdown
                    placeholder={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {icons[category]} {/* Display the category icon as placeholder */}
                        </div>
                    }
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            fontSize: '14px',
                            padding: '5px',
                        }),
                        dropdownIndicator: (provided) => ({
                            ...provided,
                            display: 'none',  // Hide the dropdown arrow
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'lightgray',
                        })
                    }}
                    menuPlacement="auto"  // Adjusts menu position based on available space
                    menuPosition="fixed"  // Ensures dropdown is fixed in the viewport
                    menuPortalTarget={document.body}  // Append the menu to the body to avoid clipping issues
                />
            </div>
        );
    };


    const handleUpdateNotes = (studentId) => {
        const requestData = {
            notes: noteText,
            course_id: classCode,
            period: period,
            rating_date: formatDate(selectedDate)
        };

        axios.post(`${apiUrl}/api/students/${studentId}/ratings/notes`, requestData)
            .then(response => {
                alert('Notes updated successfully!');
                setEditNotes(null); // Close the notes editing after saving
                setNoteText(''); // Clear the note text

                // Update the local state to reflect the new notes
                setStudents(prevStudents =>
                    prevStudents.map(student =>
                        student.primary_id === studentId
                            ? { ...student, notes: noteText } // Update notes for the specific student
                            : student
                    )
                );
            })
            .catch(error => console.error('Error updating notes:', error));
    };


    const updateSeatPosition = (studentId, seat_x, seat_y) => {
        const requestData = {
            seat_x: seat_x,
            seat_y: seat_y,
        };

        axios.post(`${apiUrl}/api/enrollments/${classCode}/${studentId}`, requestData)
            .then(response => {
                // Update local state to reflect the new position
                setStudents(prevStudents =>
                    prevStudents.map(student =>
                        student.primary_id === studentId
                            ? { ...student, seat_x: seat_x, seat_y: seat_y }
                            : student
                    )
                );
            })
            .catch(error => console.error('Error updating seat position:', error));
    };

    const handleDragStart = (student, dragEvent) => {
        setDraggedStudent(student);

        // Ensure the event is passed and check if it's available
        if (dragEvent) {
            const containerRect = dragEvent.target.getBoundingClientRect();  // Get the container position
            const initialX = dragEvent.clientX - containerRect.left;  // Initial X position of the drag
            const initialY = dragEvent.clientY - containerRect.top;   // Initial Y position of the drag
            setInitialDragPosition({ x: initialX, y: initialY });
        }
    };


    const handleDrop = (event) => {
        event.preventDefault();
        if (draggedStudent) {
            const containerRect = event.currentTarget.getBoundingClientRect();

            // Calculate the new position by subtracting the initial drag offset
            const newX = event.clientX - containerRect.left - initialDragPosition.x;
            const newY = event.clientY - containerRect.top - initialDragPosition.y;

            // Update the seat position in the database
            updateSeatPosition(draggedStudent.primary_id, newX, newY);
            setDraggedStudent(null); // Clear the dragged student
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // Necessary to allow drop
    };


    // Date navigation functions
    const handlePreviousDay = () => {
        setSelectedDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() - 1);
            return newDate;
        });
    };

    const handleNextDay = () => {
        setSelectedDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        });
    };

    const handleDateChange = (event) => {
        const selected = event.target.value; // Get the selected date string (YYYY-MM-DD)
        const [year, month, day] = selected.split('-').map(Number); // Split and convert to numbers
        // Create a new Date object with the selected year, month (0-indexed), and day
        setSelectedDate(new Date(year, month - 1, day));
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const toggleView = () => {
        setIsSeatView(prev => !prev);
    };

    const handleContainerDoubleClick = (studentId) => {
        if (editNotes === studentId) {
            setEditNotes(null); // Close notes editing
            setNoteText(''); // Clear the note text
        } else {
            setEditNotes(studentId); // Open notes editing for this student
            setNoteText(ratings[studentId]?.notes || ''); // Set existing notes based on ratings state
        }
    };

    return (
        <InstructorLayout>
            <div style={instructorCourseStyles.container}>
                <div style={instructorCourseStyles.header}>
                    <button onClick={() => navigate(-1)}><FaArrowLeft /> Back</button>
                </div>
                <div style={instructorCourseStyles.classInfo}>
                    <h1>Course: {classCode}, Period: {period}</h1>
                </div>
                {/* Date Navigation */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                    <FaArrowLeft style={{ cursor: 'pointer', fontSize: '25px', color: 'white' }} onClick={handlePreviousDay} />
                    <input
                        type="date"
                        value={formatDate(selectedDate)}
                        onChange={handleDateChange}
                        style={{ margin: '0 10px', padding: '5px', fontSize: '16px' }}
                    />
                    <FaArrowRight style={{ cursor: 'pointer', fontSize: '25px', color: 'white' }} onClick={handleNextDay} />

                    <button onClick={toggleView} style={{ marginLeft: '10px', marginTop: '-2px' }}>
                        {isSeatView ? 'Switch to Roster View' : 'Switch to Seat View'}
                    </button>
                </div>

                {isSeatView ? (
                    <>
                        {/* Seat View */}
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            style={{
                                border: '1px solid black',
                                backgroundColor: 'white',
                                width: '100%',
                                height: '600px',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                        >
                            {students.map(student => (
                                <div
                                    key={student.primary_id}
                                    draggable
                                    onDragStart={(dragEvent) => handleDragStart(student, dragEvent)}
                                    onDoubleClick={() => handleContainerDoubleClick(student.primary_id)}
                                    style={{
                                        position: 'absolute',
                                        left: student.seat_x,
                                        top: student.seat_y,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        border: '1px solid black',
                                        padding: '10px',
                                        cursor: 'move',
                                        backgroundColor: draggedStudent === student ? 'lightblue' : 'white',
                                        zIndex: editNotes === student.primary_id ? 1000 : 1, // Higher z-index when editing
                                    }}
                                >
                                    <div style={{ fontSize: '10px' }}>{student.first_name} {student.last_name}
                                        {student.notes && student.notes.trim() !== '' && (
                                            <FaRegStickyNote
                                                onClick={() => handleNotesClick(student.primary_id)}
                                                style={{ cursor: 'pointer', marginLeft: '8px' }}
                                            />
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                        {editNotes !== student.primary_id && (
                                            <>
                                                <div style={{ margin: '10px 0' }}>
                                                    {renderDropdown(student.primary_id, 'academic')}
                                                </div>
                                                <div style={{ margin: '10px 0' }}>
                                                    {renderDropdown(student.primary_id, 'behavior')}
                                                </div>
                                                <div style={{ margin: '10px 0' }}>
                                                    {renderDropdown(student.primary_id, 'attendance')}
                                                </div>
                                            </>
                                        )}
                                        {editNotes === student.primary_id && (
                                            <div>
                                                {/* Notes input field */}
                                                <textarea
                                                    rows="4"
                                                    cols="20"
                                                    placeholder="Add notes here..."
                                                    value={noteText}
                                                    onChange={e => setNoteText(e.target.value)}
                                                />
                                                <div>
                                                    <button onClick={() => handleUpdateNotes(student.primary_id)}>Save</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </>
                ) : (
                    <>
                        {/* Roster View */}
                        <table style={instructorCourseStyles.table}>
                            <thead>
                            <tr>
                                <th>Student Name</th>
                                <th>Academic</th>
                                <th>Behavior</th>
                                <th>Attendance</th>
                            </tr>
                            </thead>
                            <tbody>
                            {students.map(student => (
                                <tr key={student.primary_id}>
                                    <td>{student.first_name} {student.last_name}</td>
                                    <td>
                                        {renderIcon(FaBook, student.primary_id, 'academic', 'excellent', 'green')}
                                        {renderIcon(FaBook, student.primary_id, 'academic', 'average', 'orange')}
                                        {renderIcon(FaBook, student.primary_id, 'academic', 'needs_improvement', 'red')}
                                    </td>
                                    <td>
                                        {renderIcon(FaRegSmile, student.primary_id, 'behavior', 'excellent', 'green')}
                                        {renderIcon(FaRegMeh, student.primary_id, 'behavior', 'average', 'orange')}
                                        {renderIcon(FaRegSadCry, student.primary_id, 'behavior', 'needs_improvement', 'red')}
                                    </td>
                                    <td>
                                        {renderIcon(FaUserCheck, student.primary_id, 'attendance', 'present', 'green')}
                                        {renderIcon(FaUserCheck, student.primary_id, 'attendance', 'tardy', 'orange')}
                                        {renderIcon(FaUserCheck, student.primary_id, 'attendance', 'absent', 'red')}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                )}

                {showNotes && (
                    <div className="notes-popup">
                        <div className="popup-content">
                            <p>{studentNotes}</p>
                            <button onClick={() => setShowNotes(false)}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </InstructorLayout>
    );
};

export default InstructorCourse;
