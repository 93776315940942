import React from 'react';
import { useNavigate } from 'react-router-dom';

const FooterNav = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: '#007bff',
            color: 'white',
            position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            padding: '15px 0',
            boxShadow: '0 -3px 5px rgba(0,0,0,0.2)',
        }}>
            <button style={{
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                fontSize: '18px',
            }} onClick={() => navigate('/')}>Home</button>

            <button style={{
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                fontSize: '18px',
            }} onClick={() => navigate('/admin/parents')}>Parent</button>

            <button style={{
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                fontSize: '18px',
            }} onClick={() => navigate('/admin/instructors')}>Instructors</button>
        </div>
    );
};

export default FooterNav;