import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ParentDetails.css'; // Ensure CSS is imported

const ParentDetails = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { id } = useParams(); // Get the parent ID from the URL
    const [parent, setParent] = useState(null);
    const [children, setChildren] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchParentDetails = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/parents/${id}`);
                setParent(response.data);

                // Fetch children of this parent
                const childrenResponse = await axios.get(`${apiUrl}/api/parents/${id}/children`);
                setChildren(childrenResponse.data);

                setLoading(false);
            } catch (err) {
                setError('Error fetching parent details.');
                setLoading(false);
            }
        };

        fetchParentDetails();
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="parent-details-container">
            {parent ? (
                <div>
                    <h2>{parent.first_name} {parent.last_name}</h2>
                    <div className="parent-info">
                        <p><strong>Username:</strong> {parent.user_name}</p>
                        <p><strong>Email:</strong> {parent.email}</p>
                    </div>

                    <h3>Children</h3>
                    {children.length > 0 ? (
                        <ul>
                            {children.map(child => (
                                <li key={child.primary_id}>
                                    {child.first_name} {child.last_name} (Grade {child.grade_level})
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No children found.</p>
                    )}
                </div>
            ) : (
                <p>No parent details available.</p>
            )}
        </div>
    );
};

export default ParentDetails;
