import React, { useState, useEffect } from 'react';
import { leftContainerStyles } from '../styles/Components/LeftContainerStyles';

const LeftContainer = ({ onSelectMessageType }) => {
    const [selectedMessageType, setSelectedMessageType] = useState('index');

    useEffect(() => {
        onSelectMessageType(selectedMessageType);
    }, [selectedMessageType, onSelectMessageType]);

    const handleSelectMessageType = (type) => {
        if (selectedMessageType === type) return;
        setSelectedMessageType(type);
    };

    const isMessageTypeSelected = (type) => {
        return type === selectedMessageType || (type === 'index' && selectedMessageType === null);
    };

    return (
        <div style={leftContainerStyles.container}>
            <div style={leftContainerStyles.content}>
                <button
                    style={{
                        ...leftContainerStyles.button,
                        ...(isMessageTypeSelected('sendMessage') && leftContainerStyles.buttonSelected),
                    }}
                    onClick={() => handleSelectMessageType('sendMessage')}
                >
                    Send Message
                </button>
                <button
                    style={{
                        ...leftContainerStyles.button,
                        ...(isMessageTypeSelected('inbox') && leftContainerStyles.buttonSelected),
                    }}
                    onClick={() => handleSelectMessageType('inbox')}
                >
                    Inbox
                </button>
                <button
                    style={{
                        ...leftContainerStyles.button,
                        ...(isMessageTypeSelected('sent') && leftContainerStyles.buttonSelected),
                    }}
                    onClick={() => handleSelectMessageType('sent')}
                >
                    Sent
                </button>
                <button
                    style={{
                        ...leftContainerStyles.button,
                        ...(isMessageTypeSelected('trash') && leftContainerStyles.buttonSelected),
                    }}
                    onClick={() => handleSelectMessageType('trash')}
                >
                    Trash
                </button>
            </div>
        </div>
    );
};

export default LeftContainer;
