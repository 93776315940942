import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './StudentsTable.css';
import { Link, useNavigate } from 'react-router-dom';

const StudentsList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortOption, setSortOption] = useState('id');
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchStudents();
    }, []);

    const fetchStudents = () => {
        axios.get(`${apiUrl}/api/students`)
            .then(response => {
                setStudents(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching students:', error);
                setError('Failed to load students.');
                setLoading(false);
            });
    };

    const handleDelete = (primaryId) => {
        if (window.confirm("Are you sure? All data will be deleted.")) {
            axios.delete(`${apiUrl}/api/students/${primaryId}`)
                .then(() => {
                    alert("Student deleted successfully.");
                    setStudents(students.filter(student => student.primary_id !== primaryId));
                })
                .catch(error => {
                    console.error('Failed to delete student:', error);
                    alert('Failed to delete student.');
                });
        }
    };

    const handleEditClick = (student) => {
        setEditMode(student.primary_id);
        setEditData({ ...student });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleUpdate = (id) => {
        if (JSON.stringify(editData) === JSON.stringify(students.find(student => student.primary_id === id))) {
            alert("No changes made.");
            return;
        }

        axios.put(`${apiUrl}/api/students/${id}`, {
            first_name: editData.first_name,
            last_name: editData.last_name,
            grade_level: editData.grade_level
        })
            .then(() => {
                alert("Student updated successfully.");
                setStudents(students.map(student =>
                    student.primary_id === id ? editData : student
                ));
                setEditMode(null);
            })
            .catch(error => {
                console.error('Failed to update student:', error);
                alert('Failed to update student.');
            });
    };

    const handleSort = (option) => {
        setSortOption(option);
        let sortedStudents = [...students];
        if (option === 'id') {
            sortedStudents.sort((a, b) => a.primary_id - b.primary_id);
        } else if (option === 'firstName') {
            sortedStudents.sort((a, b) => a.first_name.localeCompare(b.first_name));
        } else if (option === 'lastName') {
            sortedStudents.sort((a, b) => a.last_name.localeCompare(b.last_name));
        } else if (option === 'gradeLevel') {
            sortedStudents.sort((a, b) => a.grade_level - b.grade_level);
        }
        setStudents(sortedStudents);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClickFind = () => {
        const [firstName, lastName] = searchTerm.split(' ');
        if (!firstName || !lastName) {
            alert('Please enter both first and last names.');
            return;
        }

        const foundStudent = students.find(student =>
            student.first_name.toLowerCase() === firstName.toLowerCase() &&
            student.last_name.toLowerCase() === lastName.toLowerCase()
        );

        if (foundStudent) {
            navigate(`/admin/student/${foundStudent.primary_id}`);
        } else {
            alert('Student not found. Please ensure the first and last names are entered correctly.');
        }
    };

    if (loading) return <p>Loading students...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="students-table">
            <div style={{ display: 'solid', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
                <button
                    onClick={() => navigate('/admin/home')}
                    style={{
                        position: 'relative',
                        left: -20,
                        top: '-50px',
                        padding: '10px 25px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Back
                </button>
            </div>
            <h2>Students List</h2>

            <div className="search-and-buttons">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Enter first and last names"
                />
                <button className="btn" onClick={handleClickFind}>Find</button>
                <button className="btn" onClick={() => navigate('/admin/student/create')}>Create New</button>
            </div>

            <div className="filter-dropdown">
                <label htmlFor="filter">Filter by: </label>
                <select id="filter" onChange={(e) => handleSort(e.target.value)}>
                    <option value="id">ID</option>
                    <option value="firstName">First Name Alphabetically</option>
                    <option value="lastName">Last Name Alphabetically</option>
                    <option value="gradeLevel">Grade Level</option>
                </select>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Primary ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Grade Level</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {students.length > 0 ? (
                        students.map(student => (
                            <tr key={student.primary_id}>
                                {editMode === student.primary_id ? (
                                    <>
                                        <td>{student.primary_id}</td>
                                        <td><input type="text" name="first_name" value={editData.first_name} onChange={handleInputChange} /></td>
                                        <td><input type="text" name="last_name" value={editData.last_name} onChange={handleInputChange} /></td>
                                        <td><input type="number" name="grade_level" value={editData.grade_level} onChange={handleInputChange} /></td>
                                        <td>
                                            <button className="save-button" onClick={() => handleUpdate(student.primary_id)}>Save</button>
                                            <button className="cancel-button" onClick={() => setEditMode(null)}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{student.primary_id}</td>
                                        <td>
                                            <Link to={`/admin/student/${student.primary_id}`}>
                                                {student.first_name}
                                            </Link>
                                        </td>
                                        <td>{student.last_name}</td>
                                        <td>{student.grade_level}</td>
                                        <td>
                                            <button className="update-button" onClick={() => handleEditClick(student)}>Update</button>
                                            <button className="delete-button" onClick={() => handleDelete(student.primary_id)}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No students found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default StudentsList;
