// TeacherCalendarStyles.js
const instructorCalendarStyles = {
    fullScreenContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        padding: '20px',
        paddingBottom: '80px',
        backgroundColor: '#fff',
        overflowX: 'hidden',
    },
    calendarSection: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '1750px', // Set max width to shrink horizontally
        margin: '0 auto',   // Center the calendar horizontally
        height: '60vh',
        padding: '10px 20px',
        overflowY: 'visible', // Ensure visibility of month navigation
    },
    eventSection: {
        flex: 1,
        padding: '20px',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 200px)',
    },
    addEventButton: {
        backgroundColor: '#28a745',
        color: '#fff',
        padding: '10px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '5px',
        zIndex: 1001
    },
    eventForm: {
        backgroundColor: '#f4f4f4',
        padding: '20px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1002,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    closeButton: {
        backgroundColor: '#ff4444',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        cursor: 'pointer',
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    deleteButton: {
        backgroundColor: '#ff4444',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        cursor: 'pointer',
    },
    updateButton: {
        backgroundColor: '#4169E1',
        color: '#fff',
        padding: '5px 10px',
        border: 'none',
        cursor: 'pointer',
    },
    eventListContainer: {
        maxHeight: '350px',  // Set a max height for the container to allow scrolling
        overflowY: 'auto',   // Enable vertical scroll
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    },

    eventBox: {
        padding: '10px',
        marginBottom: '10px',
        borderRadius: '5px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
    },
    '.dayCell': {
        color: '#333',
        borderRadius: '4px',
        padding: '10px',
        fontSize: '1.3em', // Slightly larger font size for date numbers
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    '.weekendCell': {
        backgroundColor: '#E6E6FA', // Same background for uniformity
        fontWeight: 'bold',
    },
    fcEventTitle: {
        backgroundColor: '#007bff',       // Event background color
        color: '#fff',
        padding: '2px 1px',
        borderRadius: '4px',
        margin: '2px 0',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: '0.9em',
        lineHeight: '1',
        display: 'block',
    },
    fcMoreEvents: {
        color: '#007bff',
        cursor: 'pointer',
        fontSize: '0.85em',
        marginTop: '2px',
    },
    fcMoreEvents: {
        color: '#007bff',
        cursor: 'pointer',
        fontSize: '0.85em',
        marginTop: '4px',
    },
    '.fc .fc-daygrid-event': {
        minHeight: '4px !important',
    },

    '.fc .fc-daygrid-day-events': {
        padding: '2px !important',
    },
    '.fc-daygrid-day:hover': {
        backgroundColor: '#f0f8ff !important', // Light blue or any color you prefer for highlighting
        cursor: 'pointer',
        xIndex: 1004,
    },

};

export default instructorCalendarStyles;
