import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaRegSmile, FaRegSadCry, FaRegMeh, FaBook, FaUserCheck, FaTimesCircle, FaArrowLeft } from 'react-icons/fa';
import ParentLayout from '../screens/ParentLayout';
import { AccountContext } from '../../UserAuth/Account';
import styles from './styles/ParentMainStyles';

const ParentMain = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { studentId } = useParams();
    const [student, setStudent] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getSession } = useContext(AccountContext);
    let navigate = useNavigate();

    useEffect(() => {
        const fetchSessionAndStudentData = async () => {
            try {
                const { userGroups } = await getSession();
                if (!(userGroups && userGroups.includes('Parents'))) {
                    navigate('/forbidden');
                    return;
                }
                const response = await axios.get(`${apiUrl}/api/student/${studentId}/ratings`);
                if (response.data && response.data.length > 0) {
                    const latestDate = response.data[0].rating_date;
                    const filteredData = response.data.filter(item => item.rating_date === latestDate);
                    setStudent({ ...filteredData[0], periods: filteredData });
                } else {
                    setError('No data found for the selected student.');
                }
            } catch (err) {
                console.error('Error fetching data or session:', err);
                if (err.message.includes('User not found')) {
                    navigate('/');
                } else if (err.response && err.response.status === 404) {
                    setError('No data found for the selected student.');
                } else {
                    setError('Error fetching data from server or session. Please try again later.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchSessionAndStudentData();
    }, [getSession, studentId, navigate]);

    if (loading) {
        return (
            <ParentLayout>
                <div>Loading data, please wait...</div>
            </ParentLayout>
        );
    }

    if (error) {
        return (
            <ParentLayout>
                <div style={styles.errorMessage}>
                    <p>{error}</p>
                </div>
            </ParentLayout>
        );
    }

    const getAcademicIcon = (academic) => {
        switch (academic.toLowerCase()) {
            case 'excellent': return <FaBook style={styles.icon} color="green" />;
            case 'average': return <FaBook style={styles.icon} color="orange" />;
            case 'needs_improvement': return <FaBook style={styles.icon} color="red" />;
            default: return <FaTimesCircle style={styles.icon} color="grey" />;
        }
    };

    const getBehaviorIcon = (behavior) => {
        switch (behavior.toLowerCase()) {
            case 'excellent': return <FaRegSmile style={styles.icon} color="green" />;
            case 'average': return <FaRegMeh style={styles.icon} color="orange" />;
            case 'needs_improvement': return <FaRegSadCry style={styles.icon} color="red" />;
            default: return <FaTimesCircle style={styles.icon} color="grey" />;
        }
    };

    const getAttendanceIcon = (attendance) => {
        switch (attendance.toLowerCase()) {
            case 'present': return <FaUserCheck style={styles.icon} color="green" />;
            case 'absent': return <FaUserCheck style={styles.icon} color="red" />;
            case 'tardy': return <FaUserCheck style={styles.icon} color="orange" />;
            default: return <FaTimesCircle style={styles.icon} color="grey" />;
        }
    };

    return (
        <ParentLayout>
            <div style={styles.mainContainer}>
                {/* Back Button */}
                <div style={styles.backButton} onClick={() => navigate(-1)}>
                    <FaArrowLeft style={styles.backIcon} />
                    <span>Back</span>
                </div>

                <div style={styles.headerContainer}>
                    <div style={styles.header}>
                        <p style={styles.studentName}>
                            Student Name: {student.first_name} {student.last_name}
                        </p>
                        <p style={styles.studentDetails}>Student ID: {student.student_id}</p>
                        <p style={styles.studentDetails}>Grade: {student.grade_level}</p>
                        <p style={styles.studentDetails}>Date: {new Date(student.rating_date).toLocaleDateString()}</p>
                    </div>
                </div>

                <div style={styles.tableContainer}>
                    <table border="1" style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Period</th>
                                <th style={styles.tableHeader}>Attendance</th>
                                <th style={styles.tableHeader}>Academic</th>
                                <th style={styles.tableHeader}>Behavior</th>
                            </tr>
                        </thead>
                        <tbody>
                            {student.periods && student.periods.length > 0 ? (
                                student.periods.map((periodData, index) => (
                                    <tr key={index}>
                                        <td style={styles.tableCell}>
                                            <div style={styles.periodCell}>
                                                {periodData.period}
                                                {periodData.notes && (
                                                    <FaBook
                                                        style={styles.noteIcon}
                                                        onClick={() => alert(`Note: ${periodData.notes}`)}
                                                    />
                                                )}
                                            </div>
                                        </td>
                                        <td style={styles.tableCell}>{getAttendanceIcon(periodData.attendance)}</td>
                                        <td style={styles.tableCell}>{getAcademicIcon(periodData.academic)}</td>
                                        <td style={styles.tableCell}>{getBehaviorIcon(periodData.behavior)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center' }}>No periods available for this student.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </ParentLayout>
    );
};

export default ParentMain;
