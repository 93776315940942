import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './EventDetails.css';

const EventDetails = () => {
    const { id } = useParams(); // Get the event ID from the URL
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editEvent, setEditEvent] = useState({ title: '', description: '' });
    const [isAdding, setIsAdding] = useState(false);
    const [newEvent, setNewEvent] = useState({ title: '', description: '', event_date_time: '' });

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await axios.get(`http://localhost:5000/api/events/${id}`);
                setEvent(response.data);
                setLoading(false);
            } catch (err) {
                setError('Error fetching event details.');
                setLoading(false);
            }
        };

        fetchEventDetails();
    }, [id]);

    const handleDeleteEvent = async (eventId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this event?');
        if (!confirmDelete) {
            return; // If user clicks "Cancel," do nothing
        }

        try {
            await axios.delete(`http://localhost:5000/api/events/${eventId}`);
            alert('The event has been successfully deleted.');
            // Redirect or update the state as needed
        } catch (err) {
            setError('Error deleting event.');
        }
    };

    const handleEditEventClick = () => {
        setEditEvent({
            title: event.title,
            description: event.description,
            event_date_time: event.event_date_time,  // Already included
            creator_id: event.creator_id,  // Add creator_id
            course_id: event.course_id,    // Add course_id
            category: event.category       // Add category
        });
        setIsEditing(true);
    };

    const handleEventInputChange = (event) => {
        setEditEvent({ ...editEvent, [event.target.name]: event.target.value });
    };

    const handleUpdateEvent = async () => {
        try {
            const formattedEvent = {
                ...editEvent,
                event_date_time: new Date(editEvent.event_date_time).toISOString()  // Ensure proper formatting for date
            };

            await axios.put(`http://localhost:5000/api/events/${id}`, formattedEvent);  // Send all required fields
            setEvent({ ...event, ...formattedEvent });
            setIsEditing(false);
            alert('The event has been successfully updated.');
        } catch (err) {
            console.error('Error updating event:', err.response?.data || err.message);  // Log the actual error
            setError('Error updating event.');
        }
    };


    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="event-details-container">
            {event ? (
                <div>
                    <h2>{event.title}</h2>
                    <p><strong>Date & Time:</strong> <em>{new Date(event.event_date_time).toLocaleString()}</em></p>
                    <p className="event-description">{event.description}</p>

                    <button className="edit-event-btn" onClick={handleEditEventClick}>Edit Event</button>
                    <button className="delete-event-btn" onClick={() => handleDeleteEvent(event.event_id)}>Delete Event</button>

                    {isEditing && (
                        <div className="edit-event-form">
                            <h3>Update Event</h3>
                            <input
                                type="text"
                                name="title"
                                value={editEvent.title}
                                onChange={handleEventInputChange}
                                placeholder="Enter new event title"
                            />
                            <textarea
                                name="description"
                                value={editEvent.description}
                                onChange={handleEventInputChange}
                                placeholder="Enter new event description"
                            />
                            <button onClick={handleUpdateEvent}>Save Changes</button>
                            <button onClick={() => setIsEditing(false)}>Cancel</button>
                        </div>
                    )}

                </div>
            ) : (
                <p>No event details available.</p>
            )}
        </div>
    );
};

export default EventDetails;
