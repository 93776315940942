import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLayout.css';

const AdminLayout = ({ children }) => {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const settingsRef = useRef(null);


    // Close settings modal when clicking outside
    const closeSettings = (e) => {
        if (settingsRef.current && !settingsRef.current.contains(e.target)) {
            setIsSettingsOpen(false);
        }
    };

    return (
        <div style={{ position: 'relative' }} onClick={isSettingsOpen ? closeSettings : null}>
            {/* Main content section */}
            <div style={{ minHeight: 'calc(100vh - 70px)' }}>
                {children}
            </div>


          
        </div>
    );
};

export default AdminLayout;
