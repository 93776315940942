import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './ParentsTable.css';

const ParentsList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [parents, setParents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortOption, setSortOption] = useState('id');
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchParents();
    }, []);

    const fetchParents = () => {
        axios.get(`${apiUrl}/api/parents`)
            .then(response => {
                setParents(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching parents:', error);
                setError('Failed to load parents.');
                setLoading(false);
            });
    };

    const handleDelete = (cognitoId) => {
        if (window.confirm("Are you sure? All data will be deleted.")) {
            axios.delete(`${apiUrl}/api/parents/${cognitoId}`)
                .then(() => {
                    alert("Parent deleted successfully.");
                    setParents(parents.filter(parent => parent.cognito_id !== cognitoId));
                })
                .catch(error => {
                    console.error('Failed to delete parent:', error);
                    alert('Failed to delete parent.');
                });
        }
    };

    const handleEditClick = (parent) => {
        setEditMode(parent.cognito_id);
        setEditData({ ...parent });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleUpdate = (id) => {
        axios.put(`${apiUrl}/api/parents/${id}`, editData)
            .then(() => {
                alert("Parent updated successfully.");
                setParents(parents.map(parent =>
                    parent.cognito_id === id ? editData : parent
                ));
                setEditMode(null);
            })
            .catch(error => {
                console.error('Failed to update parent:', error);
                alert('Failed to update parent.');
            });
    };

    const handleSort = (option) => {
        setSortOption(option);
        let sortedParents = [...parents];
        sortedParents.sort((a, b) => {
            if (option === 'firstName') return a.first_name.localeCompare(b.first_name);
            if (option === 'lastName') return a.last_name.localeCompare(b.last_name);
            return a.cognito_id - b.cognito_id;
        });
        setParents(sortedParents);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleClickFind = () => {
        const [firstName, lastName] = searchQuery.split(' ');
        if (!firstName || !lastName) {
            alert('Please enter both first and last names.');
            return;
        }
        const foundParent = parents.find(parent =>
            parent.first_name.toLowerCase() === firstName.toLowerCase() &&
            parent.last_name.toLowerCase() === lastName.toLowerCase()
        );
        if (foundParent) {
            navigate(`/admin/parent/${foundParent.cognito_id}`);
        } else {
            alert('Parent not found.');
        }
    };

    if (loading) return <p>Loading parents...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="parents-table">
            <h2>Parents List</h2>
            <div style={{ display: 'solid', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
                <button
                    onClick={() => navigate('/admin/home')}
                    style={{
                        position: 'relative',
                        left: -20,
                        top: '-110px',
                        padding: '10px 25px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Back
                </button>
            </div>
            <div className="search-and-buttons">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Enter first and last names"
                />
                <button className="btn" onClick={handleClickFind}>Find</button>
                <button className="btn" onClick={() => navigate('/admin/parent/create')}>Create New</button>
            </div>

            <div className="filter-dropdown">
                <label htmlFor="filter">Filter by: </label>
                <select id="filter" onChange={(e) => handleSort(e.target.value)}>
                    <option value="id">ID</option>
                    <option value="firstName">First Name</option>
                    <option value="lastName">Last Name</option>
                </select>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {parents.length > 0 ? (
                        parents.map(parent => (
                            <tr key={parent.cognito_id}>
                                {editMode === parent.cognito_id ? (
                                    <>
                                        <td>{parent.cognito_id}</td>
                                        <td><input type="text" name="first_name" value={editData.first_name} onChange={handleInputChange} /></td>
                                        <td><input type="text" name="last_name" value={editData.last_name} onChange={handleInputChange} /></td>
                                        <td><input type="text" name="user_name" value={editData.user_name} onChange={handleInputChange} /></td>
                                        <td><input type="email" name="email" value={editData.email} onChange={handleInputChange} /></td>
                                        <td>
                                            <button className="save-button" onClick={() => handleUpdate(parent.cognito_id)}>Save</button>
                                            <button className="cancel-button" onClick={() => setEditMode(null)}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{parent.cognito_id}</td>
                                        <td><Link to={`/admin/parent/${parent.cognito_id}`}>{parent.first_name}</Link></td>
                                        <td>{parent.last_name}</td>
                                        <td>{parent.user_name}</td>
                                        <td>{parent.email}</td>
                                        <td>
                                            <button className="update-button" onClick={() => handleEditClick(parent)}>Update</button>
                                            <button className="delete-button" onClick={() => handleDelete(parent.cognito_id)}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No parents found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ParentsList;
