import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { FaRegSmile, FaRegSadCry, FaRegMeh, FaBook, FaUserCheck, FaTimesCircle } from 'react-icons/fa';
import ParentLayout from '../screens/ParentLayout';
import { useNavigate } from 'react-router-dom';
import parentHomeStyles from './styles/ParentHomeStyles';
import { AccountContext } from '../../UserAuth/Account';

const ParentHome = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [error, setError] = useState(null);
    const { getSession } = useContext(AccountContext);

    let navigate = useNavigate();
    const [parent, setParent] = useState(null);
    const [studentRatings, setStudentRatings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                // Fetch session data
                const { userAttributes, userGroups } = await getSession();

                // Check if the user belongs to the "Parents" user group
                if (!(userGroups && userGroups.includes('Parents'))) {
                    navigate('/forbidden');  // Redirect to Forbidden page
                    return;
                }

                return userAttributes['custom:id'];  // Return parentId
            } catch (err) {
                console.error("Session error:", err);
                setError("Failed to get session. Please log in.");
                navigate('/'); // Redirect to login page if error in session or user not found
            }
        };

        fetchSession().then(parentId => {
            if (parentId) {
                fetchParentData(parentId);  // Fetch parent data if parentId is available
            }
        });
    }, [getSession, navigate]);

    const fetchParentData = async (parentId) => {
        try {
            // Fetch Parent Info from backend
            const parentResponse = await axios.get(`${apiUrl}/api/parent/${parentId}`);
            setParent(parentResponse.data);

            // Fetch Ratings Info from backend
            const ratingsResponse = await axios.get(`${apiUrl}/api/parent/${parentId}/ratings/latest`);
            setStudentRatings(ratingsResponse.data);

            // Update localStorage to store the latest fetched data
            localStorage.setItem('parentData', JSON.stringify(parentResponse.data));
            localStorage.setItem('studentRatings', JSON.stringify(ratingsResponse.data));
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching data. Please try again later.');
        } finally {
            setLoading(false); // Set loading to false when the data fetch is completed
        }
    };

    const handleStudentClick = (studentId) => {
        navigate(`/parent-main/${studentId}`, { state: { parent, studentRatings } });
    };

    // Icon handling code
    const getAcademicIcon = (academic) => {
        switch (academic) {
            case 'excellent': return <FaBook color="green" />;
            case 'average': return <FaBook color="orange" />;
            case 'needs_improvement': return <FaBook color="red" />;
            default: return <FaTimesCircle color="grey" />;
        }
    };

    const getBehaviorIcon = (behavior) => {
        switch (behavior) {
            case 'excellent': return <FaRegSmile color="green" />;
            case 'average': return <FaRegMeh color="orange" />;
            case 'needs_improvement': return <FaRegSadCry color="red" />;
            default: return <FaTimesCircle color="grey" />;
        }
    };

    const getAttendanceIcon = (attendance) => {
        switch (attendance) {
            case 'present': return <FaUserCheck color="green" />;
            case 'absent': return <FaUserCheck color="red" />;
            case 'tardy': return <FaUserCheck color="orange" />;
            default: return <FaTimesCircle color="grey" />;
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Show a loading message until data is fetched
    }

    return (
        <ParentLayout>
            <div style={parentHomeStyles.background}>
                {error && <div style={{ color: 'red' }}>{error}</div>} {/* Show error message */}
                {parent && (
                    <div style={parentHomeStyles.header}>
                        Welcome, {parent.first_name} {parent.last_name}
                    </div>
                )}
                {studentRatings.length ? (
                    studentRatings.reduce((acc, current) => {
                        let student = acc.find(st => st.student_id === current.student_id);
                        if (!student) {
                            student = {
                                student_id: current.student_id,
                                first_name: current.first_name,
                                last_name: current.last_name,
                                grade_level: current.grade_level,
                                rating_date: current.rating_date,
                                periods: []
                            };
                            acc.push(student);
                        }
                        student.periods.push(current);
                        return acc;
                    }, []).map((student, index) => (
                        <div key={index} style={parentHomeStyles.studentCard} onClick={() => handleStudentClick(student.student_id)}>
                            <div>Student Name: {student.first_name} {student.last_name}</div>
                            <div>Student ID: {student.student_id}</div>
                            <div>Grade: {student.grade_level}</div>
                            <div>Date: {new Date(student.rating_date).toISOString().split('T')[0]}</div>
                            <div style={parentHomeStyles.performanceIndicator}>
                                {student.periods.length > 0 ? (
                                    student.periods.map((periodData, idx) => (
                                        <div key={idx} style={parentHomeStyles.periodItem}>
                                            <span>Period {periodData.period}</span>
                                            <div style={parentHomeStyles.iconRow}>
                                                {getAttendanceIcon(periodData.attendance)}
                                                {getAcademicIcon(periodData.academic)}
                                                {getBehaviorIcon(periodData.behavior)}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div>No ratings available for today.</div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No student ratings available.</div>
                )}
            </div>
        </ParentLayout>
    );
};

export default ParentHome;
