import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ParentsList from './ParentsList';
import axios from 'axios';

const ManageParents = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [parents, setParents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchParents = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/parents`);
                setParents(response.data);
            } catch (error) {
                console.error('Failed to fetch parents:', error);
            }
        };

        fetchParents();
    }, []);

    const handleDelete = async (cognito_id) => {
        try {
            await axios.delete(`${apiUrl}/api/parents/${cognito_id}`);
            setParents(parents.filter(parent => parent.cognito_id !== cognito_id));
        } catch (error) {
            console.error('Failed to delete parent:', error);
        }
    };

    const handleClickCreate = () => {
        navigate('/admin/parent/create');
    };

    return (
        <>
            <ParentsList parents={parents} onDelete={handleDelete} onCreate={handleClickCreate} />
        </>
    );
};

export default ManageParents;
