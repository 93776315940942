import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InstructorsList from './InstructorsList';
import axios from 'axios';
import './ManageInstructors.css'; // Make sure to include this CSS file

const ManageInstructors = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [instructors, setInstructors] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInstructors = async () => {
            try {
                const response = await axios.get(`http://localhost:5000/api/instructors`);
                setInstructors(response.data);
            } catch (error) {
                console.error('Failed to fetch instructors:', error);
            }
        };

        fetchInstructors();
    }, []);

    const handleDelete = async (cognito_id) => {
        try {
            await axios.delete(`${apiUrl}/api/instructors/${cognito_id}`);
            setInstructors(instructors.filter(instructor => instructor.cognito_id !== cognito_id));
        } catch (error) {
            console.error('Failed to delete instructor:', error);
        }
    };

    return (
        <>
            <InstructorsList instructors={instructors} onDelete={handleDelete} />
        </>
    );
};

export default ManageInstructors;
