import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InstructorLayout from './InstructorLayout';
import instructorCalendarStyles from './styles/TeacherCalendarStyles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';
import { AccountContext } from '../../UserAuth/Account';
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const TeacherCalendar = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [eventFormVisible, setEventFormVisible] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [newEvent, setNewEvent] = useState({ eventName: '', category: '', description: '', date: currentDate, courseId: '' });
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [updatingEventId, setUpdatingEventId] = useState(null);
    const [courses, setCourses] = useState([]);
    const [instructorId, setInstructorId] = useState(null);

    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        const fetchInstructorId = async () => {
            try {
                const { userAttributes } = await getSession();
                const id = userAttributes['custom:id'] || null;
                setInstructorId(id);
            } catch (error) {
                console.error('Failed to retrive instructor ID:', error);
            }
        };
        fetchInstructorId();
    }, [getSession]);


    useEffect(() => {
        if (instructorId) {
            loadEvents(currentDate);
            fetchAllEvents();
            fetchCourses();
        }
    }, [currentDate, instructorId]);


    const fetchCourses = async () => {
        try {
            const response = await fetch(`/api/courses/instructor/${instructorId}`); // Ensure this endpoint returns the correct courses
            const data = await response.json();
            const courseIds = data.map(course => course.course_id);
            setCourses(courseIds);
            console.log(`Fetched courses for instructor ${instructorId}:`, courseIds); // Log the fetched course IDs
        } catch (error) {
            console.error('Error fetching the courses:', error);
        }
    };


    const fetchEventsForDate = async (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD'); // Keep it simple
        try {
            const response = await fetch(`/api/events/creator/${instructorId}?date=${formattedDate}`); // Use date query
            const data = await response.json();
            return data.map(event => ({
                id: event.event_id,
                title: event.title,
                start: event.event_date_time, // Use as-is from backend
                description: event.description,
                category: event.category,
                course_id: event.course_id,
            }));
        } catch (error) {
            console.error('Error fetching events:', error);
            return [];
        }
    };

    const fetchAllEvents = async () => {
        try {
            const response = await fetch(`/api/events/creator/${instructorId}`);
            const data = await response.json();
            const mappedEvents = data.map(event => ({
                id: event.event_id,
                title: event.title,
                start: event.event_date_time, // Use as-is from backend
                description: event.description,
                category: event.category,
                course_id: event.course_id,
            }));
            setAllEvents(mappedEvents);
        } catch (error) {
            console.error('Error fetching all events:', error);
        }
    };


    // Additional debugging and timezone application in loadEvents function
    const loadEvents = async (date) => {
        const fetchedEvents = await fetchEventsForDate(date);
        setEvents(fetchedEvents);
    };

    // Additional debug in handleDateClick to track date clicks
    const handleDateClick = (info) => {
        const clickedDate = moment(info.date).format('YYYY-MM-DD'); // Format directly to YYYY-MM-DD
        setCurrentDate(info.date); // Keep the current date as a Date object for UI
        setNewEvent(prev => ({ ...prev, date: info.date })); // Update the newEvent state

        fetchEventsForDate(clickedDate) // Pass the formatted date string
            .then(events => {
                setEvents(events); // Update events for the clicked date
                console.log("Events for clicked date:", events);
            })
            .catch(error => console.error("Error fetching events for selected date:", error));
    };


    const addEvent = async (e) => {
        e.preventDefault();

        const courseId = Number(newEvent.courseId);
        // Validate that the courseId entered by the user is one of the instructor's courses
        if (!courses.includes(courseId)) {
            alert("You are not authorized to create events for this course. Please contact the admin if you believe this is an error.");
            return;
        }
        try {
            const formattedDate = moment.tz(newEvent.date, 'America/Los_Angeles').format('YYYY-MM-DD');
            const newEventEntry = {
                creator_id: instructorId,
                course_id: newEvent.courseId,
                event_date_time: `${formattedDate} 09:00:00`,
                title: newEvent.eventName,
                category: newEvent.category,
                description: newEvent.description,
            };

            const response = await fetch('/api/events', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newEventEntry),
            });

            if (!response.ok) throw new Error('Error adding event');

            await loadEvents(newEvent.date);
            await fetchAllEvents();

            setEventFormVisible(false);
            resetForm();
        } catch (error) {
            console.error('Error adding event:', error);
        }
    };

    const fetchUpdatedEvent = async (eventId) => {
        try {
            const response = await fetch(`/api/events/${eventId}`);
            const updatedEvent = await response.json();
        } catch (error) {
            console.error('Error fetching updated event:', error);
        }
    };


    const updateEvent = async (e) => {
        e.preventDefault();

        const courseId = Number(newEvent.courseId);
        if (!courses.includes(courseId)) {
            alert("You are not authorized to update events for this course. Please contact the admin if you believe this is an error.");
            return;
        }

        if (!updatingEventId) {
            console.error("Error: updatingEventId is undefined");
            return;
        }


        // Format date in specific timezone
        const formattedDate = moment.tz(newEvent.date, 'America/Los_Angeles').format('YYYY-MM-DD');

        const updatedEvent = {
            event_date_time: `${formattedDate} 09:00:00`, // Use consistent date and time
            title: newEvent.eventName,
            category: newEvent.category,
            description: newEvent.description,
            course_id: courseId
        };

        try {

            const response = await fetch(`/api/events/${updatingEventId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedEvent),
            });

            const responseData = await response.json();

            if (response.ok) {
                await fetchUpdatedEvent(updatingEventId);
                await loadEvents(currentDate); // Refresh events for the selected date
                await fetchAllEvents();        // Refresh the entire events list
                setEventFormVisible(false);    // Close the event form
                setIsUpdating(false);          // Reset updating state
                resetForm();                   // Clear the form fields
            } else {
                console.error("Failed to update event:", responseData);
            }
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };



    const deleteEvent = async (eventId) => {
        try {
            const response = await fetch(`/api/events/${eventId}`, { method: 'DELETE' });
            if (!response.ok) throw new Error('Error deleting event');

            await loadEvents(currentDate);
            await fetchAllEvents();

        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    // Debugging in handleUpdateButtonClick
    const handleUpdateButtonClick = (event) => {
        // Convert event.start to America/Los_Angeles timezone and work directly with moment
        const eventStartDate = moment.tz(event.start, 'America/Los_Angeles').startOf('day');

        setNewEvent({
            eventName: event.title,
            category: event.category,
            description: event.description,
            date: eventStartDate.toDate(),
            courseId: event.course_id || ''
        });

        // Set currentDate with eventStartDate.toDate() only after fixing the timezone
        if (!isUpdating) {
            setCurrentDate(eventStartDate.toDate()); // Convert to Date only at the last moment
        }

        setIsUpdating(true);
        setUpdatingEventId(event.id);
        setEventFormVisible(true);
    };


    // Ensure resetForm uses currentDate, add logging
    const resetForm = () => {
        setNewEvent({
            eventName: '',
            category: '',
            description: '',
            date: currentDate,
            courseId: '' // Reset courseId as well
        });
        setIsUpdating(false);
        setUpdatingEventId(null);
    };


    const renderEventContent = (eventInfo) => {
        return (
            <div className="fcEventTitle" style={instructorCalendarStyles.fcEventTitle}>
                <div>{eventInfo.event.title}</div>
            </div>
        );
    };


    return (
        <InstructorLayout>
            <div style={instructorCalendarStyles.fullScreenContainer}>
                <div style={{ ...instructorCalendarStyles.calendarSection, height: '60vh' }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={allEvents}
                        dateClick={handleDateClick}
                        height="auto"
                        selectable={true}
                        eventContent={renderEventContent}
                        dayMaxEventRows={2}              // Show up to 2 events before using "+X more"
                        moreLinkClick="popover"          // Display remaining events in a popover
                        eventDisplay="block"              // Ensure each event displays as a block element
                        dayCellDidMount={(info) => {
                            info.el.classList.add('dayCell');//Applying class to each cell individually
                            const day = info.date.getDay();
                            if (day === 0 || day === 6) { // 0 is Sunday, 6 is Saturday
                                info.el.classList.add('weekendCell');
                            }
                        }}
                    />
                    <button style={instructorCalendarStyles.addEventButton} onClick={() => {
                        resetForm();
                        //setNewEvent({ ...newEvent, date: currentDate });
                        setIsUpdating(false);
                        setEventFormVisible(true);
                    }}>
                        Add Event
                    </button>
                </div>

                <div style={instructorCalendarStyles.eventSection}>
                    <h3>Events on {moment.tz(currentDate, userTimezone).format('ddd MMM DD YYYY')}</h3>
                    <div style={instructorCalendarStyles.eventListContainer}>
                        {events.length > 0 ? (
                            events.map((event) => (
                                <div key={event.id} style={instructorCalendarStyles.eventBox}>
                                    <p><strong>{event.title}</strong>: {event.description}</p>
                                    <button style={instructorCalendarStyles.updateButton} onClick={() => handleUpdateButtonClick(event)}>Update</button>
                                    <button style={instructorCalendarStyles.deleteButton} onClick={() => deleteEvent(event.id)}>Delete</button>
                                </div>
                            ))
                        ) : (
                            <p>No events today.</p>
                        )}
                    </div>
                </div>


                {eventFormVisible && (
                    <div>
                        <div style={instructorCalendarStyles.overlay}></div>
                        <div style={instructorCalendarStyles.eventForm}>
                            <button style={instructorCalendarStyles.closeButton} onClick={() => setEventFormVisible(false)}>X</button>
                            <h4>{isUpdating ? 'Update Event' : 'Add New Event'}</h4>
                            <form onSubmit={isUpdating ? updateEvent : addEvent} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <label>Event Name:</label>
                                <input
                                    type="text"
                                    placeholder="Event Name"
                                    value={newEvent.eventName}
                                    onChange={(e) => setNewEvent({ ...newEvent, eventName: e.target.value })}
                                />
                                <label>Category:</label>
                                <input
                                    type="text"
                                    placeholder="Category"
                                    value={newEvent.category}
                                    onChange={(e) => setNewEvent({ ...newEvent, category: e.target.value })}
                                />
                                <label>Description:</label>
                                <input
                                    type="text"
                                    placeholder="Description"
                                    value={newEvent.description}
                                    onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                                />
                                <label>Course ID: (Cannot be modified)</label> {/* New input for Course ID */}
                                <input
                                    type="text"
                                    placeholder="Course ID"
                                    value={newEvent.courseId}
                                    onChange={(e) => setNewEvent({ ...newEvent, courseId: e.target.value })}
                                />
                                <button type="submit">
                                    {isUpdating ? 'Update Event' : 'Submit'}
                                </button>
                            </form>
                            <button onClick={() => setEventFormVisible(false)}>Cancel</button>
                        </div>
                    </div>
                )}

            </div>
        </InstructorLayout>
    );
};

export default TeacherCalendar;
