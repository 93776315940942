import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ParentLayout from '../screens/ParentLayout';
import parentCalendarStyles from './styles/ParentCalendarStyles';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../UserAuth/Account';

// Set up localizer
const localizer = momentLocalizer(moment);

// Custom Toolbar without View Tabs
const CustomToolbar = (toolbar) => {
    return (
        <div style={parentCalendarStyles.toolbarContainer}>
            {/* Back and Next buttons aligned with Month title */}
            <div style={parentCalendarStyles.navControls}>
                <button
                    style={parentCalendarStyles.navButton}
                    onClick={() => toolbar.onNavigate('PREV')}
                >
                    Back
                </button>
                <span style={parentCalendarStyles.monthTitle}>{toolbar.label}</span>
                <button
                    style={parentCalendarStyles.navButton}
                    onClick={() => toolbar.onNavigate('NEXT')}
                >
                    Next
                </button>
            </div>

            {/* Today button centered under the nav controls */}
            <div style={parentCalendarStyles.todayContainer}>
                <button
                    style={parentCalendarStyles.todayButton}
                    onClick={() => toolbar.onNavigate('TODAY')}
                >
                    Today
                </button>
            </div>
        </div>
    );
};

const ParentCalendar = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [events, setEvents] = useState([]);
    const [selectedDateEvents, setSelectedDateEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const { getSession } = useContext(AccountContext); // Use context for session
    let navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const { userGroups } = await getSession();
                // Check if the user belongs to the "Parents" user group
                if (!(userGroups && userGroups.includes('Parents'))) {
                    navigate('/forbidden');  // Redirect to Forbidden page
                    return;
                }

            } catch (error) {
                console.error('Error checking authentication:', error);
                navigate('/'); // Redirect to login on error
            }
        };

        checkAuthentication();

        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/parent/${1}/events`); // Change `1` to `parentId` once dynamic
                setEvents(response.data.map(event => ({
                    id: event.event_id,
                    title: `${event.title} (${event.student_first_name} ${event.student_last_name})`,
                    start: new Date(event.event_date_time),
                    end: new Date(event.event_date_time),
                    extendedProps: {
                        category: event.category,
                        description: event.description,
                        courseSubject: event.course_subject,
                    },
                })));
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
        fetchEvents();
    }, [getSession, navigate]);

    const handleSelectSlot = ({ start }) => {
        const clickedDate = moment(start).format('YYYY-MM-DD');
        const filteredEvents = events.filter(
            event => moment(event.start).format('YYYY-MM-DD') === clickedDate
        );
        // Reset the selected date events to prevent stacking
        setSelectedDateEvents([]);
        setTimeout(() => {
            setSelectedDateEvents(filteredEvents);
            setSelectedDate(clickedDate);
        }, 0);
    };

    return (
        <ParentLayout>
            <div style={parentCalendarStyles.container}>
                <div style={parentCalendarStyles.calendarContainer}>
                    <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        selectable
                        views={['month']}  // Only keep "month" view to disable view switching
                        style={{ height: 500 }}
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={() => { }}  // Disable default event click behavior
                        components={{
                            toolbar: CustomToolbar,
                            dateCellWrapper: ({ children, value }) => (
                                <div
                                    style={
                                        moment(value).isSame(moment(), 'day')
                                            ? { ...parentCalendarStyles.todayCell } // Full highlight for today
                                            : parentCalendarStyles.calendarCell
                                    }
                                >
                                    {children}
                                </div>
                            ),
                            event: () => null, // Disable full event rendering in cells
                        }}
                    />
                </div>
                <div style={parentCalendarStyles.eventsContainer}>
                    <h2 style={parentCalendarStyles.fixedTitle}>Events for Selected Day {selectedDate ? `(${selectedDate})` : ''}</h2>
                    {selectedDateEvents.length > 0 ? (
                        <ul style={parentCalendarStyles.eventList}>
                            {selectedDateEvents.map(event => (
                                <li key={event.id} style={parentCalendarStyles.eventItem}>
                                    <strong>{event.title}</strong>
                                    <p>Category: {event.extendedProps.category}</p>
                                    <p>{event.extendedProps.description}</p>
                                    <p>Course: {event.extendedProps.courseSubject}</p>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No events for this date.</p>
                    )}
                </div>
            </div>
        </ParentLayout>
    );
};

export default ParentCalendar;
