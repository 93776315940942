import React, { useState } from 'react';
import axios from 'axios';
import './CourseSchedule.css';

const CourseSchedule = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newSchedule, setNewSchedule] = useState({ course_id: '', course_subject: '', day_of_week: '', period: '', instructor_id: '' });
    const [editMode, setEditMode] = useState(null);
    const [editData, setEditData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    // Fetch schedules based on search query
    const fetchSchedules = async () => {
        if (!searchQuery) {
            alert("Please enter a valid Instructor ID");
            return;
        }

        console.log("Instructor ID search query:", searchQuery); // Debugging log

        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`${apiUrl}/api/course-schedule?instructor_id=${encodeURIComponent(searchQuery)}`);
            setSchedules(response.data);
        } catch (error) {
            console.error('Error fetching course schedules:', error);
            setError('Failed to load schedules.');
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSchedule({ ...newSchedule, [name]: value });
    };

    const handleAddSchedule = async () => {
        const { course_id, course_subject, day_of_week, period, instructor_id } = newSchedule;

        if (!course_id || !day_of_week || !period || !course_subject || !instructor_id) {
            alert("All fields are required to add a new schedule.");
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/course-schedule`, {
                course_id,
                course_subject,
                day_of_week,
                period,
                instructor_id,
            });

            if (response.status === 201) {
                alert("Schedule added successfully!");
                setNewSchedule({ course_id: '', course_subject: '', day_of_week: '', period: '', instructor_id: '' });
                fetchSchedules(); // Refresh schedules after adding
            } else {
                alert("Schedule was not added as expected. Please try again.");
            }
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 409) {
                    alert(`Failed to add schedule: ${data.error} - A schedule with this course, day, and period already exists.`);
                } else if (status === 400) {
                    alert(`Failed to add schedule: ${data.error} - Please check all required fields.`);
                } else {
                    alert(`Failed to add schedule: ${data.error || "Unexpected error occurred."}`);
                }
            } else {
                console.error('Failed to add schedule:', error);
                alert('An unexpected error occurred while adding the schedule.');
            }
        }
    };

    const handleEditClick = (schedule) => {
        setEditMode(schedule.id);
        setEditData({ ...schedule });
    };

    const handleUpdateSchedule = async (id) => {
        try {
            await axios.put(`${apiUrl}/api/course-schedule/${id}`, editData);
            setSchedules(schedules.map(schedule => schedule.id === id ? editData : schedule));
            setEditMode(null);
            setEditData({});
        } catch (error) {
            console.error('Failed to update schedule:', error);
            alert('Failed to update schedule.');
        }
    };

    const handleDeleteSchedule = async (id) => {
        if (window.confirm("Are you sure you want to delete this schedule?")) {
            try {
                await axios.delete(`${apiUrl}/api/course-schedule/${id}`);
                setSchedules(schedules.filter(schedule => schedule.id !== id));
            } catch (error) {
                console.error('Failed to delete schedule:', error);
                alert('Failed to delete schedule.');
            }
        }
    };

    if (loading) return <p>Loading schedules...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="course-schedule">
            <h2>Course Schedule</h2>

            {/* Search Form */}
            <div className="search-schedule">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Enter Instructor ID"
                />
                <button onClick={fetchSchedules}>Search Schedule</button>
            </div>

            {/* Add New Schedule */}
            <div className="add-schedule">
                <input
                    type="number"
                    name="course_id"
                    value={newSchedule.course_id}
                    onChange={handleInputChange}
                    placeholder="Course ID"
                />
                <input
                    type="text"
                    name="course_subject"
                    value={newSchedule.course_subject}
                    onChange={handleInputChange}
                    placeholder="Course Subject"
                />
                <input
                    type="text"
                    name="day_of_week"
                    value={newSchedule.day_of_week}
                    onChange={handleInputChange}
                    placeholder="Day of Week"
                />
                <input
                    type="number"
                    name="period"
                    value={newSchedule.period}
                    onChange={handleInputChange}
                    placeholder="Period"
                />
                <input
                    type="number"
                    name="instructor_id"
                    value={newSchedule.instructor_id}
                    onChange={handleInputChange}
                    placeholder="Instructor ID"
                />
                <button onClick={handleAddSchedule}>Add Schedule</button>
            </div>

            {/* Schedule Table */}
            <table>
                <thead>
                    <tr>
                        <th>Instructor First Name</th>
                        <th>Instructor Last Name</th>
                        <th>Course ID</th>
                        <th>Course Subject</th>
                        <th>Day of Week</th>
                        <th>Period</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {schedules.map(schedule => (
                        <tr key={schedule.id}>
                            {editMode === schedule.id ? (
                                <>
                                    <td><input type="text" value={editData.instructor_first_name} onChange={(e) => setEditData({ ...editData, instructor_first_name: e.target.value })} /></td>
                                    <td><input type="text" value={editData.instructor_last_name} onChange={(e) => setEditData({ ...editData, instructor_last_name: e.target.value })} /></td>
                                    <td><input type="number" value={editData.course_id} onChange={(e) => setEditData({ ...editData, course_id: e.target.value })} /></td>
                                    <td><input type="text" value={editData.course_subject} onChange={(e) => setEditData({ ...editData, course_subject: e.target.value })} /></td>
                                    <td><input type="text" value={editData.day_of_week} onChange={(e) => setEditData({ ...editData, day_of_week: e.target.value })} /></td>
                                    <td><input type="number" value={editData.period} onChange={(e) => setEditData({ ...editData, period: e.target.value })} /></td>
                                    <td>
                                        <button onClick={() => handleUpdateSchedule(schedule.id)}>Save</button>
                                        <button onClick={() => setEditMode(null)}>Cancel</button>
                                    </td>
                                </>
                            ) : (
                                <>
                                    <td>{schedule.instructor_first_name}</td>
                                    <td>{schedule.instructor_last_name}</td>
                                    <td>{schedule.course_id}</td>
                                    <td>{schedule.course_subject}</td>
                                    <td>{schedule.day_of_week}</td>
                                    <td>{schedule.period}</td>
                                    <td>
                                        <button onClick={() => handleEditClick(schedule)}>Edit</button>
                                        <button onClick={() => handleDeleteSchedule(schedule.id)}>Delete</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CourseSchedule;
