import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making HTTP requests
import './CreateCourse.css'; // Reuse the same CSS for styling

/*
    CreateCourse component is used to create a new course.
*/
const CreateCourse = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();
    const [course, setCourse] = useState({
        course_id: '',
        instructor_id: '',
        subject: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCourse(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting', course);

        // Make the POST request to the backend to create the course
        try {
            const response = await axios.post(`${apiUrl}/api/courses`, course);
            console.log(response.data);
            alert('Course created successfully!');

            // Redirect to the courses list after successful submission
            navigate('/admin/courses');
        } catch (error) {
            console.error('Failed to create course:', error);
            alert('Failed to create course. Please check the console for details.');
        }
    };

    const handleClose = () => {
        navigate('/admin/courses');
    };

    return (
        <div className='create-course' style={{ color: 'red', position: 'relative', padding: '30px' }}>
        <button className="close-btn" onClick={handleClose}>X</button>
            <hr />
            <h2>Create New Courses</h2>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-group">
                    <label htmlFor="courseId" style={{ color: 'black' }}>Course ID:</label>
                    <input id="courseId" name="course_id" value={course.course_id} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="instructorId" style={{ color: 'black' }}>Instructor ID:</label>
                    <input id="instructorId" name='instructor_id' value={course.instructor_id} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <div className="form-group">
                    <label htmlFor="subject" style={{ color: 'black' }}>Subject:</label>
                    <input id="subject" name='subject' value={course.subject} onChange={handleChange} required style={{ color: 'black' }} />
                </div>
                <button type="submit" className="submit-btn" style={{ color: 'black' }}>Create Course</button>
            </form>
        </div>
    );
}

export default CreateCourse;
