import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './Account';

const Status = () => {
    const [status, setStatus] = useState(false);
    const [userAttributes, setUserAttributes] = useState(null);
    const [username, setUsername] = useState(null);
    const [userGroups, setUserGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getSession } = useContext(AccountContext);

    useEffect(() => {
        setLoading(true);
        getSession()
            .then(({ session, userAttributes, username, userGroups }) => {
                setUserAttributes(userAttributes);
                setUsername(username);
                setUserGroups(userGroups);
                setStatus(true);
            })
            .catch(err => {
                console.error("Session error:", err);
                setError("Failed to get session. Please log in.");
                setStatus(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [getSession]);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    <p>{status ? "You are logged in!" : "Please log in"}</p>
                    {status && userAttributes && (
                        <div>
                            <p>Username: {username}</p>
                            <p>First Name: {userAttributes?.given_name || "N/A"}</p>
                            <p>Last Name: {userAttributes?.family_name || "N/A"}</p>
                            <p>Email: {userAttributes?.email || "N/A"}</p>
                            <p>Custom ID: {userAttributes?.['custom:id'] || "N/A"}</p>
                            {userGroups.length > 0 && (
                                <p>Groups: {userGroups.join(', ')}</p>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Status;
