import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EventsList from './EventsList';
import axios from 'axios';

const ManageEvents = () => {
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('http://localhost:5000/api/events');
                setEvents(response.data);
            } catch (error) {
                console.error('Failed to fetch events:', error);
            }
        };

        fetchEvents();
    }, []);

    const handleDelete = async (event_id) => {
        try {
            await axios.delete(`http://localhost:5000/api/events/${event_id}`);
            setEvents(events.filter(event => event.event_id !== event_id));
        } catch (error) {
            console.error('Failed to delete event:', error);
        }
    };

    const handleClickCreate = () => {
        navigate('/admin/event/create');
    };

    return (
        <>
            <EventsList events={events} onDelete={handleDelete} onCreate={handleClickCreate} />
        </>
    );
};

export default ManageEvents;
