import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App'; // Importing App component

//Checking if i can commit 8-28-2024 :))

const container = document.getElementById('root');
const root = createRoot(container); // Creating a root

// Rendering the App component inside the root
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);