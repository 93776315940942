import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // To extract the periodId from the route params
import InstructorLayout from './InstructorLayout';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from '../../UserAuth/Account';

const PeriodDetails = () => {
    const { periodId } = useParams(); // Extract periodId from the URL
    const [periodDetails, setPeriodDetails] = useState(null); // Store fetched course details
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { getSession } = useContext(AccountContext); // Use context for session
    let navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const { userGroups } = await getSession();
                // Check if the user belongs to the "Parents" user group
                if (!(userGroups && userGroups.includes('Parents'))) {
                    navigate('/forbidden');  // Redirect to Forbidden page
                    return;
                }

            } catch (error) {
                console.error('Error checking authentication:', error);
                navigate('/'); // Redirect to login on error
            }
        };

        checkAuthentication();

        const fetchPeriodDetails = async () => {
            setLoading(true);
            setError(null);
            try {
                console.log("fetching period details for periodId: ", periodId);//DEBUGGING LINE
                const response = await fetch(`/api/instructors/101/courses/${periodId}`); // Fetch the course details using periodId
                if (!response.ok) {
                    throw new Error('Failed to fetch period details');
                }
                const data = await response.json();
                console.log("Heres the data in 'data': ", data);//DEBUGGING LINE
                setPeriodDetails(data); // Store the course details
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (periodId) {
            fetchPeriodDetails(); // Fetch details if periodId is available
        }
    }, [periodId, getSession, navigate]);

    //Debugging the periodDetails object
    useEffect(() => {
        if (periodDetails) {
            console.log('Period Details Object: ', periodDetails);
        }
    }, [periodDetails]);

    return (
        <InstructorLayout>
        <div>
            <h2>Period Details</h2>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {periodDetails && (
                    <div>
                                                            {/* DEBUGGING: Check if periodDetails has the expected properties */}
                        {console.log('Period Details Object:', periodDetails)}
                    <p>Subject: {periodDetails.subject || 'N/A'}</p>
                    <p>Class Strength: {periodDetails.studentCount || 'N/A'}</p>
                    <h3>Students List:</h3>
                    <ul>
                        {periodDetails.studentNames.split('\n').map((student, index) => (
                            <li key={index}>{student}</li> // Render each student on a new line
                        )) || 'No Students Enrolled'}
                    </ul>
                </div>
            )}
        </div>
        </InstructorLayout>
            );
};

export default PeriodDetails;
