const parentCalendarStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px',
        width: '100%',
    },
    calendarContainer: {
        margin: '0px',
        width: '100%',
        maxWidth: '1200px',
        marginBottom: '20px',
        border: '1px solid black',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 768px)': {
            maxWidth: '100%',
            height: '300px',
        },
    },
    eventsContainer: {
        marginBottom: '100px',
        width: '100%',
        maxWidth: '900px',
        backgroundColor: '#f9f9f9',
        padding: '0px',
        borderRadius: '8px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
        overflowY: 'auto',
        height: '370px',
        '@media (max-width: 768px)': {
            maxWidth: '100%',
            padding: '10px',
            height: '200px',
        },
    },
    fixedTitle: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#f9f9f9',
        padding: '10px',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        textAlign: 'center',
        borderBottom: '1px solid #ddd',
        zIndex: 10,
    },
    eventList: {
        marginTop: '10px',
        paddingTop: '10px',
    },
    eventItem: {
        marginBottom: '10px',
        padding: '10px',
        backgroundColor: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
        '@media (max-width: 768px)': {
            padding: '8px',
        },
    },
    todayCell: {
        backgroundColor: '#d1e7dd',
        border: 'none',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    calendarCell: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '1px solid #ddd', // Vertical line between day columns
    },
    toolbarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '10px',
    },
    navControls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0px',
        width: '100%',
    },
    navButton: {
        padding: '5px 15px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    monthTitle: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        textAlign: 'center',
        flexGrow: '1',
    },
    todayContainer: {
        marginBottom: '5px',
    },
    todayButton: {
        padding: '5px 15px',
        border: '1px solid #007bff',
        borderRadius: '4px',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
    '@media (max-width: 600px)': {
        container: {
            padding: '10px',
        },
        calendarContainer: {
            width: '100%',
            maxWidth: '100%',
            marginBottom: '10px',
        },
        eventsContainer: {
            width: '100%',
            maxWidth: '100%',
            padding: '8px',
        },
        eventItem: {
            padding: '6px',
        },
        calendarHeader: {
            fontSize: '0.9rem',
        },
        calendarCell: {
            width: '100%',
            height: '100%',
        },
    },
};

export default parentCalendarStyles;
