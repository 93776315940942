import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InstructorLayout from './InstructorLayout';
import InstructorHomeStyles from './styles/InstructorHomeStyles';
import { AccountContext } from '../../UserAuth/Account';

const InstructorHome = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [instructorPeriods, setInstructorPeriods] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getSession } = useContext(AccountContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { userAttributes, userGroups } = await getSession();

                // Check if the user belongs to the "Instructors" user group
                if (!(userGroups && userGroups.includes('Instructors'))) {
                    navigate('/forbidden');  // Redirect to Forbidden page if not an instructor
                    return;
                }

                return userAttributes['custom:id'];  // Return instructorId
            } catch (err) {
                console.error("Session error:", err);
                setError("Failed to get session. Please log in.");
                navigate('/'); // Redirect to login page if not logged in
            }
        };

        fetchSession().then(instructorId => {
            if (instructorId) {
                fetchInstructorData(instructorId);  // Fetch instructor data if instructorId is available
            }
        });
    }, [getSession, navigate]);

    const fetchInstructorData = async (instructorId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/courses/instructor/today/${instructorId}`);
            setInstructorPeriods(response.data);
        } catch (error) {
            console.error('Error fetching instructor periods:', error);
            setError('Error fetching data.');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;  // Show a loading message while fetching data
    }

    return (
        <InstructorLayout>
            <div style={InstructorHomeStyles.container}>
                {error && <div style={{ color: 'red' }}>{error}</div>}  {/* Display error if it occurs */}
                {instructorPeriods.length > 0 && (
                    <div style={InstructorHomeStyles.welcomeText}>
                        <h1>Welcome, {instructorPeriods[0].first_name} {instructorPeriods[0].last_name}</h1>
                    </div>
                )}
                {instructorPeriods.map((currentPeriod, index) => (
                    <div key={index} style={InstructorHomeStyles.periodCard}
                        onClick={() => navigate(`/instructor_course/${currentPeriod.course_id}`, {
                            state: { classCode: currentPeriod.course_id, period: currentPeriod.period }
                        })}>
                        <div>Period: {currentPeriod.period}</div>
                        <div>Class: {currentPeriod.subject}</div>
                        <div>Class Code: {currentPeriod.course_id}</div>
                        <div>Students: {currentPeriod.number_of_students}</div>
                    </div>
                ))}
            </div>
        </InstructorLayout>
    );
};

export default InstructorHome;
