import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './InstructorsTable.css';

const InstructorsList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [instructors, setInstructors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortOption, setSortOption] = useState('id');
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchInstructors();
    }, []);

    const fetchInstructors = () => {
        axios.get(`${apiUrl}/api/instructors`)
            .then(response => {
                setInstructors(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching instructors:', error);
                setError('Failed to load instructors.');
                setLoading(false);
            });
    };

    const handleDelete = (cognitoId) => {
        if (window.confirm("Are you sure? All data will be deleted.")) {
            axios.delete(`${apiUrl}/api/instructors/${cognitoId}`)
                .then(() => {
                    alert("Instructor deleted successfully.");
                    setInstructors(instructors.filter(instructor => instructor.cognito_id !== cognitoId));
                })
                .catch(error => {
                    console.error('Failed to delete instructor:', error);
                    alert('Failed to delete instructor.');
                });
        }
    };

    const handleEditClick = (instructor) => {
        setEditMode(instructor.cognito_id);
        setEditData({ ...instructor });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleUpdate = (id) => {
        if (JSON.stringify(editData) === JSON.stringify(instructors.find(instructor => instructor.cognito_id === id))) {
            alert("No changes made.");
            return;
        }

        axios.put(`${apiUrl}/api/instructors/${id}`, editData)
            .then(() => {
                alert("Instructor updated successfully.");
                setInstructors(instructors.map(instructor =>
                    instructor.cognito_id === id ? editData : instructor
                ));
                setEditMode(null);
            })
            .catch(error => {
                console.error('Failed to update instructor:', error);
                alert('Failed to update instructor.');
            });
    };

    // Sorting function
    const handleSort = (option) => {
        setSortOption(option);
        let sortedInstructors = [...instructors];
        if (option === 'id') {
            sortedInstructors.sort((a, b) => a.cognito_id - b.cognito_id);
        } else if (option === 'firstName') {
            sortedInstructors.sort((a, b) => a.first_name.localeCompare(b.first_name));
        } else if (option === 'lastName') {
            sortedInstructors.sort((a, b) => a.last_name.localeCompare(b.last_name));
        }
        setInstructors(sortedInstructors);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClickFind = () => {
        const [firstName, lastName] = searchTerm.split(' ');
        if (!firstName || !lastName) {
            alert('Please enter both first and last names.');
            return;
        }
        const foundInstructor = instructors.find(instructor =>
            instructor.first_name.toLowerCase() === firstName.toLowerCase() &&
            instructor.last_name.toLowerCase() === lastName.toLowerCase()
        );

        if (foundInstructor) {
            navigate(`/admin/instructor/${foundInstructor.cognito_id}`);
        } else {
            alert('Instructor not found. Please check names and try again.');
        }
    };

    if (loading) return <p>Loading instructors...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="instructors-table">
            <div style={{ display: 'solid', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
                <button
                    onClick={() => navigate('/admin/home')}
                    style={{
                        position: 'relative',
                        left: -20,
                        top: '-50px',
                        padding: '10px 25px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Back
                </button>
            </div>
            <h2>Instructors List</h2>

            {/* Search and Buttons */}
            <div className="search-and-buttons">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Enter first and last names"
                />
                <button className="btn" onClick={handleClickFind}>Find</button>
                <button className="btn" onClick={() => navigate('/admin/instructor/create')}>Create New</button>
            </div>

            {/* Filter dropdown */}
            <div className="filter-dropdown">
                <label htmlFor="filter">Filter by: </label>
                <select id="filter" onChange={(e) => handleSort(e.target.value)}>
                    <option value="id">ID</option>
                    <option value="firstName">First Name Alphabetically</option>
                    <option value="lastName">Last Name Alphabetically</option>
                </select>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Cognito ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {instructors.length > 0 ? (
                        instructors.map(instructor => (
                            <tr key={instructor.cognito_id}>
                                {editMode === instructor.cognito_id ? (
                                    <>
                                        <td>{instructor.cognito_id}</td>
                                        <td><input type="text" name="first_name" value={editData.first_name} onChange={handleInputChange} /></td>
                                        <td><input type="text" name="last_name" value={editData.last_name} onChange={handleInputChange} /></td>
                                        <td><input type="text" name="user_name" value={editData.user_name} onChange={handleInputChange} /></td>
                                        <td><input type="email" name="email" value={editData.email} onChange={handleInputChange} /></td>
                                        <td>
                                            <button className="save-button" onClick={() => handleUpdate(instructor.cognito_id)}>Save</button>
                                            <button className="cancel-button" onClick={() => setEditMode(null)}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{instructor.cognito_id}</td>
                                        <td><Link to={`/admin/instructor/${instructor.cognito_id}`}>{instructor.first_name}</Link></td>
                                        <td>{instructor.last_name}</td>
                                        <td>{instructor.user_name}</td>
                                        <td>{instructor.email}</td>
                                        <td>
                                            <button className="update-button" onClick={() => handleEditClick(instructor)}>Update</button>
                                            <button className="delete-button" onClick={() => handleDelete(instructor.cognito_id)}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No instructors found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default InstructorsList;
