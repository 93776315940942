import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaEnvelope, FaCog } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import ParentSettings from './ParentSettings';

const ParentLayout = ({ children }) => {
    const navigate = useNavigate();
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const settingsRef = useRef(null);

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };

    const closeSettings = (e) => {
        // Close settings if clicking outside of the settings module
        if (settingsRef.current && !settingsRef.current.contains(e.target)) {
            setIsSettingsOpen(false);
        }
    };

    const iconStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '12px',
    };

    return (
        <div style={{ position: 'relative' }} onClick={isSettingsOpen ? closeSettings : null}>
            {/* Main Content */}
            <div style={{ minHeight: 'calc(100vh - 70px)' }}>
                {children}
            </div>

            {/* Bottom Navigation Bar */}
            <div style={{
                height: '70px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                background: '#ddd',
                position: 'fixed',
                width: '100%',
                bottom: 0,
                boxShadow: '0 -1px 5px rgba(0,0,0,0.2)',
                padding: '10px 0'
            }}>
                <div style={iconStyle} onClick={() => navigate('/parent-home')}>
                    <FaHome size="1.5em" />
                    <span>Home</span>
                </div>
                <div style={iconStyle} onClick={() => navigate('/parent-calendar')}>
                    <BsCalendar size="1.5em" />
                    <span>Calendar</span>
                </div>
                <div style={iconStyle} onClick={() => navigate('/messagepage')}>
                    <FaEnvelope size="1.5em" />
                    <span>Messages</span>
                </div>
                <div style={iconStyle} onClick={toggleSettings}>
                    <FaCog size="1.5em" />
                    <span>Settings</span>
                </div>
            </div>

            {/* Settings Module */}
            {isSettingsOpen && (
                <div ref={settingsRef} style={{ zIndex: 1000 }}>
                    <ParentSettings toggleSettings={toggleSettings} />
                </div>
            )}
        </div>
    );
};

export default ParentLayout;
