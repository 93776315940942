import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment-timezone';

const AdminCalendar = () => {
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [eventFormVisible, setEventFormVisible] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [newEvent, setNewEvent] = useState({ eventName: '', category: '', description: '', date: currentDate, courseId: '' });
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [updatingEventId, setUpdatingEventId] = useState(null);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [eventToDelete, setEventToDelete] = useState(null);
    const [creatorId, setCreatorId] = useState('');


    useEffect(() => {
        loadEvents(currentDate);
        fetchAllEvents();
    }, [currentDate]);

    const fetchEventsForDate = async (date) => {
        const formattedDate = moment.tz(date, 'America/Los_Angeles').format('YYYY-MM-DD');
        try {
            const response = await fetch(`/api/events`);
            const data = await response.json();
            return data
                .filter(event => event.event_date_time.startsWith(formattedDate))
                .map(event => ({
                    id: event.event_id,
                    title: event.title,
                    start: moment.tz(event.event_date_time, 'America/Los_Angeles').format('YYYY-MM-DD'),
                    description: event.description,
                    category: event.category,
                    courseId: event.course_id
                }));
        } catch (error) {
            console.error('Error fetching events:', error);
            return [];
        }
    };

    const fetchAllEvents = async () => {
        try {
            const response = await fetch(`/api/events`);
            const data = await response.json();
            setAllEvents(data.map(event => ({
                id: event.event_id,
                title: event.title,
                start: moment.tz(event.event_date_time, 'America/Los_Angeles').format('YYYY-MM-DD'),
                description: event.description,
                category: event.category,
                courseId: event.course_id
            })));
        } catch (error) {
            console.error('Error fetching all events:', error);
        }
    };

    const loadEvents = async (date) => {
        const fetchedEvents = await fetchEventsForDate(date);
        setEvents(fetchedEvents);
    };

    const handleDateClick = (info) => {
        setCurrentDate(info.date);
        setNewEvent(prev => ({ ...prev, date: info.date }));
        setCreatorId(getLoggedInUserId());
        fetchEventsForDate(info.date).then(events => console.log("Events for clicked date:", events));
    };


    const addEvent = async (e) => {
        e.preventDefault();
        const formattedDate = moment.tz(newEvent.date, 'America/Los_Angeles').format('YYYY-MM-DD');

        // Assuming you have a function or a way to get the logged-in user's ID
        const creatorId = getLoggedInUserId();

        const newEventEntry = {
            creator_id: creatorId,
            event_date_time: `${formattedDate} 09:00:00`,
            title: newEvent.eventName,
            category: newEvent.category,
            description: newEvent.description,
            course_id: newEvent.courseId
        };

        try {
            const response = await fetch('/api/events', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newEventEntry),
            });

            if (!response.ok) throw new Error('Error adding event');
            await loadEvents(newEvent.date);
            await fetchAllEvents();

            setEventFormVisible(false);
            resetForm();
        } catch (error) {
            console.error('Error adding event:', error);
        }
    };

    const getLoggedInUserId = () => {
        const user = JSON.parse(localStorage.getItem('user')); // Assuming the user is stored in localStorage
        return user ? user.id : null;  // Adjust the structure based on your storage method
    };


    const updateEvent = async (e) => {
        e.preventDefault();
        if (!updatingEventId) return;

        const formattedDate = moment.tz(newEvent.date, 'America/Los_Angeles').format('YYYY-MM-DD');
        const updatedEvent = {
            event_date_time: `${formattedDate} 09:00:00`,
            title: newEvent.eventName,
            category: newEvent.category,
            description: newEvent.description,
            course_id: newEvent.courseId
        };

        try {
            const response = await fetch(`/api/events/${updatingEventId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedEvent),
            });

            if (!response.ok) throw new Error('Error updating event');
            await loadEvents(currentDate);
            await fetchAllEvents();

            setEventFormVisible(false);
            setIsUpdating(false);
            resetForm();
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };

    const [feedbackMessage, setFeedbackMessage] = useState('');

    const deleteEvent = async () => {
        if (!eventToDelete) return;

        try {
            const response = await fetch(`/api/events/${eventToDelete}`, { method: 'DELETE' });
            if (!response.ok) throw new Error('Error deleting event');

            // Set success message to be displayed in the UI
            setFeedbackMessage('Event deleted successfully.');

            // Reload events to reflect changes
            await loadEvents(currentDate);
            await fetchAllEvents();

            setDeleteModalVisible(false); // Close the modal after deletion
        } catch (error) {
            console.error('Error deleting event:', error);

            // Set error message to be displayed in the UI
            setFeedbackMessage('Failed to delete event.');
        }
    };


    const handleUpdateButtonClick = (event) => {
        const eventStartDate = moment.tz(event.start, 'America/Los_Angeles').startOf('day');
        setNewEvent({
            eventName: event.title,
            category: event.category,
            description: event.description,
            date: eventStartDate.toDate(),
            courseId: event.courseId
        });
        setCreatorId(event.creator_id);
        setCurrentDate(eventStartDate.toDate());
        setIsUpdating(true);
        setUpdatingEventId(event.id);
        setEventFormVisible(true);
    };

    const resetForm = () => {
        setNewEvent({ eventName: '', category: '', description: '', date: currentDate });
        setIsUpdating(false);
        setUpdatingEventId(null);
    };

    const renderEventContent = (eventInfo) => {
        return (
            <div>
                {eventInfo.event.title}
            </div>
        );
    };

    return (
        <AdminLayout>
            <div style={{ display: 'solid', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
                <button
                    onClick={() => navigate('/admin/home')}
                    style={{
                        position: 'relative',
                        left: 0,
                        top: '-70px',
                        padding: '10px 25px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Back
                </button>

                <div style={{ width: '100%', marginTop: '-70px', marginBottom: '-80px', height: '90vh' }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={allEvents}
                        dateClick={handleDateClick}
                        height="auto"
                        selectable={true}
                        eventContent={renderEventContent}
                        dayMaxEventRows={2}
                        moreLinkClick="popover"
                    />
                </div>

                <div>
                    <h3 style={{ color: '#FFFFFF', marginTop: '-80px' }}>Events on {moment.tz(currentDate, 'America/Los_Angeles').format('ddd MMM DD YYYY')}</h3>
                    <div>
                        {events.length > 0 ? (
                            events.map((event) => (
                                <div key={event.id} style={{ border: '1px solid #ddd', padding: '10px', margin: '5px' }}>
                                    <p style={{ color: '#FFFFFF' }}>
                                        <strong style={{ color: '#FFFFFF' }}>{event.title}:</strong> <span className="event-description">{event.description}</span>
                                    </p>
                                    <button onClick={() => handleUpdateButtonClick(event)}>Update</button>
                                    <button onClick={() => { setDeleteModalVisible(true); setEventToDelete(event.id); }}>Delete</button>
                                </div>
                            ))
                        ) : (
                            <p>No events today.</p>
                        )}
                    </div>
                </div>

                {eventFormVisible && (
                    <div>
                        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)' }}></div>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '20px', borderRadius: '8px', zIndex: 1000 }}>
                            <button onClick={() => setEventFormVisible(false)}>X</button>
                            <h4>{isUpdating ? 'Update Event' : 'Add New Event'}</h4>
                            <form onSubmit={isUpdating ? updateEvent : addEvent} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <label>Event Name:</label>
                                <input
                                    type="text"
                                    placeholder="Event Name"
                                    value={newEvent.eventName}
                                    onChange={(e) => setNewEvent({ ...newEvent, eventName: e.target.value })}
                                />
                                <label>Category:</label>
                                <input
                                    type="text"
                                    placeholder="Category"
                                    value={newEvent.category}
                                    onChange={(e) => setNewEvent({ ...newEvent, category: e.target.value })}
                                />
                                <label>Description:</label>
                                <input
                                    type="text"
                                    placeholder="Description"
                                    value={newEvent.description}
                                    onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                                />
                                <label>Course ID:</label>
                                <input
                                    type="text"
                                    placeholder="Course ID"
                                    value={newEvent.courseId}
                                    onChange={(e) => setNewEvent({ ...newEvent, courseId: e.target.value })}
                                    disabled={isUpdating} // Disable the field when updating
                                    style={{
                                        backgroundColor: isUpdating ? '#f0f0f0' : 'white',
                                        color: isUpdating ? 'gray' : 'black',
                                    }}
                                />
                                {isUpdating && (
                                <p style={{ color: 'red', fontSize: '12px', margin: 0 }}>
                                Course ID cannot be changed.
                                </p>
                                )
                                }
                                <button type="submit">{isUpdating ? 'Update' : 'Add'} Event</button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Delete Confirmation Modal */}
                {isDeleteModalVisible && (
                    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '20px', borderRadius: '8px' }}>
                            <h4>Are you sure you want to delete this event?</h4>
                            <button onClick={() => setDeleteModalVisible(false)}>Cancel</button>
                            <button onClick={deleteEvent}>Delete</button>
                        </div>
                    </div>
                )}
            </div>
        </AdminLayout>
    );
};

export default AdminCalendar;
