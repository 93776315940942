import img from '../../../imgs/background.jpg';
export const messagePageStyles = {
    container: {
        position: 'relative',
        backgroundImage: `url(${img})`, // Apply background image to the container
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // Ensure the container fills the viewport height
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-between', 
        padding: '20px', // Add padding to ensure content doesn't touch the edges
    },
};
