import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaEnvelope, FaCog } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import LeftContainer from './components/LeftContainer';
import MessageList from './components/MessageList';
import { messagePageStyles } from './styles/MessagePageStyles';
import { AccountContext } from '../../UserAuth/Account';

const MessagePage = () => {
    const [messageType, setMessageType] = useState('inbox');
    const [userID, setUserID] = useState(null);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [userGroup, setUserGroup] = useState(null);
    const { getSession, logout } = useContext(AccountContext);
    const navigate = useNavigate();
    const settingsRef = useRef(null);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { userAttributes, userGroups } = await getSession();
                setUserID(userAttributes['custom:id']);

                if (userGroups.includes('Parents')) {
                    setUserGroup('Parents');
                } else if (userGroups.includes('Instructors')) {
                    setUserGroup('Instructors');
                } else {
                    setUserGroup(null);
                }
            } catch (err) {
                console.error("Failed to fetch session", err);
                setUserGroup(null);
            }
        };
        fetchSession();
    }, [getSession]);

    const handleSelectMessageType = (type) => {
        setMessageType(type);
    };

    const iconStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#333',
    };

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };

    const closeSettings = (e) => {
        if (settingsRef.current && !settingsRef.current.contains(e.target)) {
            setIsSettingsOpen(false);
        }
    };

    const handleLogout = () => {
        logout();
        localStorage.clear(); // Clear any stored session information
        navigate('/'); // Redirect to root after logging out
    };


    if (!userID) {
        return <div>Loading...</div>;
    }

    return (
        <div style={messagePageStyles.container} onClick={isSettingsOpen ? closeSettings : null}>
            <div style={messagePageStyles.contentWrapper}>
                <LeftContainer onSelectMessageType={handleSelectMessageType} />
                <MessageList userId={userID} messageType={messageType} />
            </div>

            {/* Bottom Navigation Bar */}
            <div style={{
                height: '70px',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                background: '#ddd',
                position: 'fixed',
                width: '100%',
                bottom: 0,
                boxShadow: '0 -1px 5px rgba(0,0,0,0.2)',
                padding: '10px 0',
                zIndex: 1000,
            }}>
                <div
                    style={iconStyle}
                    onClick={() => {
                        if (userGroup === 'Parents') {
                            navigate('/parent-home');
                        } else if (userGroup === 'Instructors') {
                            navigate('/instructor_home');
                        } else {
                            navigate('/');
                        }
                    }}
                >
                    <FaHome size="1.5em" />
                    <span>Home</span>
                </div>
                <div
                    style={iconStyle}
                    onClick={() => {
                        if (userGroup === 'Parents') {
                            navigate('/parent-calendar');
                        } else if (userGroup === 'Instructors') {
                            navigate('/instructor-calendar');
                        } else {
                            navigate('/');
                        }
                    }}
                >
                    <BsCalendar size="1.5em" />
                    <span>Calendar</span>
                </div>
                <div style={iconStyle} onClick={() => navigate('/messagepage')}>
                    <FaEnvelope size="1.5em" />
                    <span>Messages</span>
                </div>
                <div style={iconStyle} onClick={toggleSettings}>
                    <FaCog size="1.5em" />
                    <span>Settings</span>
                </div>
            </div>

            {/* Settings Dropdown */}
            {isSettingsOpen && (
                <div ref={settingsRef} style={{
                    position: 'absolute',
                    bottom: '80px',
                    right: '10px',
                    background: '#fff',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                    padding: '10px',
                    zIndex: 1000,
                    borderRadius: '5px'
                }}>
                    <div onClick={handleLogout} style={{ cursor: 'pointer', padding: '5px 0' }}>
                        Log Out
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessagePage;
