import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StudentsList from './StudentsList';
import axios from 'axios';

const ManageStudents = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [students, setStudents] = useState([]);
    const navigate = useNavigate();

    // Fetch students from the back-end on component mount
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/students`);
                setStudents(response.data);
            } catch (error) {
                console.error('Failed to fetch students:', error);
            }
        };

        fetchStudents();
    }, []);

    // Handle deleting a student
    const handleDelete = async (primary_id) => {
        try {
            await axios.delete(`${apiUrl}/api/students/${primary_id}`);
            setStudents(students.filter(student => student.primary_id !== primary_id));
        } catch (error) {
            console.error('Failed to delete student:', error);
        }
    };

    // Redirect to the 'Create New Student' page
    const handleClickCreate = () => {
        navigate('/admin/student/create');
    };

    return (
        <>
            <StudentsList students={students} onDelete={handleDelete} onCreate={handleClickCreate} />
        </>
    );
};

export default ManageStudents;
