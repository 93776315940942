// InstructorSettings.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { AccountContext } from '../../UserAuth/Account';

const InstructorSettings = ({ toggleSettings }) => {
    const navigate = useNavigate();
    const { logout } = useContext(AccountContext);

    const settingsStyles = {
        container: {
            position: 'fixed',
            right: 0,
            top: 0,
            height: '100%',
            width: '300px',
            backgroundColor: '#f8f8f8',
            boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.3)',
            padding: '20px',
            transition: 'transform 0.3s ease-in-out',
            zIndex: 1000,
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
        },
        closeButton: {
            fontSize: '1.5em',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
        },
        settingItem: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '15px',
            cursor: 'pointer',
        },
        settingIcon: {
            marginRight: '10px',
        },
    };

    const handleLogout = () => {
        logout();
        localStorage.clear(); // Clear any stored session information
        navigate('/'); // Redirect to root after logging out
    };

    return (
        <div style={settingsStyles.container}>
            {/* Header */}
            <div style={settingsStyles.header}>
                <h3>Settings</h3>
                <button style={settingsStyles.closeButton} onClick={toggleSettings}>&times;</button>
            </div>

            {/* Log Out Button */}
            <div style={settingsStyles.settingItem} onClick={handleLogout}>
                <FaSignOutAlt style={settingsStyles.settingIcon} />
                <span>Log Out</span>
            </div>
        </div>
    );
};

export default InstructorSettings;
