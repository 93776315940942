import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './CoursesTable.css';

const CoursesList = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [editData, setEditData] = useState({});
    const [sortOption, setSortOption] = useState('id');
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchCourses();
    }, []);

    const fetchCourses = () => {
        axios.get(`${apiUrl}/api/courses`)
            .then(response => {
                setCourses(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
                setError('Failed to load courses.');
                setLoading(false);
            });
    };

    const handleDelete = (courseId) => {
        if (window.confirm("Are you sure? All data will be deleted.")) {
            axios.delete(`${apiUrl}/api/courses/${courseId}`)
                .then(() => {
                    alert("Course deleted successfully.");
                    setCourses(courses.filter(course => course.course_id !== courseId));
                })
                .catch(error => {
                    console.error('Failed to delete course:', error);
                    alert('Failed to delete course.');
                });
        }
    };

    const handleEditClick = (course) => {
        setEditMode(course.course_id);
        setEditData({ ...course });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    };

    const handleUpdate = (id) => {
        axios.put(`${apiUrl}/api/courses/${id}`, editData)
            .then(() => {
                alert("Course updated successfully.");
                setCourses(courses.map(course =>
                    course.course_id === id ? editData : course
                ));
                setEditMode(null);
            })
            .catch(error => {
                console.error('Failed to update course:', error);
                alert('Failed to update course.');
            });
    };

    const handleSort = (option) => {
        setSortOption(option);
        let sortedCourses = [...courses];
        if (option === 'subject') {
            sortedCourses.sort((a, b) => a.subject.localeCompare(b.subject));
        } else {
            sortedCourses.sort((a, b) => a.course_id - b.course_id);
        }
        setCourses(sortedCourses);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleClickFind = () => {
        const foundCourse = courses.find(course =>
            course.subject.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (foundCourse) {
            navigate(`/admin/course/${foundCourse.course_id}`);
        } else {
            alert('Course not found.');
        }
    };

    if (loading) return <p>Loading courses...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="courses-table">
            <div style={{ display: 'solid', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
                <button
                    onClick={() => navigate('/admin/home')}
                    style={{
                        position: 'relative',
                        left: -20,
                        top: '-50px',
                        padding: '10px 25px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Back
                </button>
            </div>
            <h2>Courses List</h2>

            <div className="search-and-buttons">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Enter course subject"
                />
                <button className="btn" onClick={handleClickFind}>Find</button>
                <button className="btn" onClick={() => navigate('/admin/course/create')}>Create New</button>
            </div>

            <div className="course-schedule-button">
                <button className="btn" onClick={() => navigate('/admin/course/schedule')}>Course Schedule</button>
            </div>

            <div className="filter-dropdown">
                <label htmlFor="filter">Filter by: </label>
                <select id="filter" onChange={(e) => handleSort(e.target.value)}>
                    <option value="id">ID</option>
                    <option value="subject">Subject Alphabetically</option>
                </select>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Course ID</th>
                        <th>Subject</th>
                        <th>Instructor ID</th>
                        <th>Admin ID</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.length > 0 ? (
                        courses.map(course => (
                            <tr key={course.course_id}>
                                <td>{course.course_id}</td>
                                {editMode === course.course_id ? (
                                    <>
                                        <td>
                                            <input
                                                type="text"
                                                name="subject"
                                                value={editData.subject}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="instructor_id"
                                                value={editData.instructor_id || ''}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="admin_id"
                                                value={editData.admin_id || ''}
                                                onChange={handleInputChange}
                                            />
                                        </td>
                                        <td>
                                            <button onClick={() => handleUpdate(course.course_id)}>Save</button>
                                            <button onClick={() => setEditMode(null)}>Cancel</button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>{course.subject}</td>
                                        <td>{course.instructor_id || 'N/A'}</td>
                                        <td>{course.admin_id || 'N/A'}</td>
                                        <td>
                                            <button onClick={() => handleEditClick(course)}>Update</button>
                                            <button onClick={() => handleDelete(course.course_id)}>Delete</button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No courses found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default CoursesList;
