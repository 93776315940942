import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CreateEvent.css';

const CreateEvent = () => {
    const navigate = useNavigate();
    const [event, setEvent] = useState({
        course_id: '',
        creator_id: '',
        event_date_time: '',
        title: '',
        category: '',
        description: ''
    });

    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEvent(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting', event);

        setError(null);

        try {
            // POST request to the server
            const response = await axios.post('http://localhost:5000/api/events', event);

            console.log('Response:', response.data);
            alert('Event created successfully!');

            // Reset the form
            setEvent({
                course_id: '',
                creator_id: '',
                event_date_time: '',
                title: '',
                category: '',
                description: ''
            });

            // Redirect to the events list
            navigate('/admin/events');
        } catch (error) {
            console.error('Failed to create event:', error.response?.data || error.message);

            if (error.response) {
                setError(error.response.data.message || 'An error occurred while creating the event.');
            } else if (error.request) {
                setError('No response from server. Please check your network or try again later.');
            } else {
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    const handleClose = () => {
        navigate('/admin/events');
    };

    return (
        <div className="create-event-popup">
            {/* Close button */}
            <button className="close-btn" onClick={handleClose}>X</button>

            <h2>Create New Event</h2>
            <form onSubmit={handleSubmit} className="form-container">
                {error && <p className="error-message">{error}</p>}
                <div className="form-group">
                    <label htmlFor="courseId">Course ID:</label>
                    <input
                        id="courseId"
                        name="course_id"
                        value={event.course_id}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="creatorId">Creator ID:</label>
                    <input
                        id="creatorId"
                        name="creator_id"
                        value={event.creator_id}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="eventDateTime">Event Date & Time:</label>
                    <input
                        id="eventDateTime"
                        type="datetime-local"
                        name="event_date_time"
                        value={event.event_date_time}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input
                        id="title"
                        name="title"
                        value={event.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="category">Category:</label>
                    <input
                        id="category"
                        name="category"
                        value={event.category}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={event.description}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className="submit-btn">Create Event</button>
            </form>
        </div>
    );
};

export default CreateEvent;
