export const leftContainerStyles = {
    container: {
        width: '25%', // Ensure enough space for the content
        height: '100vh', // Full viewport height
        backgroundColor: 'rgba(244, 244, 244, 0.6)', // Slightly transparent background
        padding: '20px',
        boxSizing: 'border-box', // Include padding in the width and height calculations
        position: 'fixed', // Fixed position
        left: '0',
        top: '0px',

        // Prevent overflow of content
        overflowY: 'auto', // Allow vertical scrolling if content exceeds height
        overflowX: 'hidden', // Hide horizontal overflow to prevent scrollbars

        // Flexbox to center content vertically and horizontally
        display: 'flex',
        flexDirection: 'column', // Arrange items in a column
        justifyContent: 'flex-start', // Start at the top, adjust if needed
        alignItems: 'center', // Align items horizontally in the center
    },
    content: {
        width: '100%', // Full width of the container
        display: 'flex',
        flexDirection: 'column', // Stack buttons vertically
        alignItems: 'center', // Align buttons in the center horizontally
        justifyContent: 'flex-start', // Start stacking from the top
        paddingBottom: '20px', // Add padding at the bottom to prevent clipping
    },
    button: {
        backgroundColor: 'rgba(0, 123, 255, 0.3)', // Blue and slightly translucent
        color: 'white',
        padding: '15px',
        border: 'none',
        borderRadius: '100px',
        cursor: 'pointer',
        marginBottom: '20px',
        display: 'block',
        transition: 'background-color 0.3s ease', // Smooth transition for background color change

        // Text styles
        fontSize: '1rem', // Regular font size
        textAlign: 'center', // Center the text
        whiteSpace: 'nowrap', // Prevent text wrapping
        overflow: 'hidden', // Prevent text from overflowing
        textOverflow: 'ellipsis', // Show ellipsis if text is too long

        // Adjust sizing to prevent clipping
        minWidth: '160px', // Minimum width for buttons
        maxWidth: '90%', // Maximum width to prevent oversized buttons
        width: 'auto', // Flexible width based on content
    },
    buttonSelected: {
        backgroundColor: 'rgba(0, 123, 255, 1)', // Fully opaque blue for selected button
        opacity: 1, // Opaque when selected
    },

    // Media queries for small screens (mobile)
    '@media (max-width: 600px)': {
        container: {
            width: '100%', // Full width on small screens
            padding: '10px', // Reduce padding to fit mobile screens
        },
        button: {
            fontSize: '0.9rem', // Slightly smaller font size for buttons
            padding: '12px', // Reduce padding for better fit
            minWidth: '130px', // Reduce minimum width for smaller screens
        },
    },
};
